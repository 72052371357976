import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ApiService } from '../services/api.service'
import { FormGroup, ReactiveFormsModule } from '@angular/forms'
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap'
import { environment } from '../../environments/environment'
import { setTime } from 'ngx-bootstrap/chronos/utils/date-setters';

@Component({
	selector: 'app-profile-page',
	templateUrl: './profile-page.component.html',
	styleUrls: ['./profile-page.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ProfilePageComponent implements OnInit {
	id;
	profile: any = {}
	phonenumber
	password;
	newPassword
	confirmPassword
	s3Url = environment.s3_url + 'thumb_profiles/'
	errorMessage = null
	successMessage = null
	constructor(
		private api: ApiService
	) { }

	ngOnInit() {
		this.id = localStorage.getItem('user')
		this.api.getProfile(this.id)
			.subscribe((res: any) => {
				this.profile = Object.assign({}, res);
				this.phonenumber = res.countrycode + res.phoneno
			})
	}

	resetPwd() {
		if (this.confirmPassword === this.newPassword) {
			this.api.userAuth({
				email: this.profile.email,
				password: this.password
			}).subscribe((res: any) => {
				console.log(res)
				if (this.checkPasswords(this.newPassword)) {
					this.api.setUserPassword(res.result.id, this.newPassword)
						.subscribe((res) => {
							this.errorMessage = null;
							this.successMessage = 'Password reset successfully'
							setTimeout(() => {
								this.successMessage = null
							}, 5000)
							this.confirmPassword = ''
							this.newPassword = ''
							console.log(res)
						}, (err) => {
							this.errorMessage = err.message
						})
				} else {
					this.errorMessage = 'Password should be of atleast 8 characters long, must include atleast an alphabet, number and a symbol'
				}
			}, (err) => {
				this.errorMessage = 'Password entered is wrong!'
			})

		} else {
			this.errorMessage = "Confirm password doesn't match. Please try again"
		}

	}

	checkPasswords(pass) { // here we have the 'passwords' group

		let strongPass = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\.])(?=.{8,})");
		if (pass.match(strongPass))
			return true
		else
			return false
	}

}

import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, Inject} from '@angular/core';
import { environment } from '../../../environments/environment'
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSelect } from '@angular/material'
import { MatFormField } from '@angular/material/form-field'
import { ApiService } from '../../services/api.service'
@Component({
  selector: 'app-staffcontact',
  templateUrl: './staffcontact.component.html',
  styleUrls: ['./staffcontact.component.scss']
})
export class StaffcontactComponent implements OnInit {
@Input('product') product;
@ViewChild('form') form;
@ViewChild('loader')loader;
currency
price
accessCode
encRequest
order
orderId
total
orderStatus
balanceAmount
countries
selectedCountry
charges
country
userId = localStorage.getItem('user')
productUnavailable = false
constructor(
      private api: ApiService,
      private ref: ChangeDetectorRef,
      public dialogRef: MatDialogRef<StaffcontactComponent>,
        @Inject(MAT_DIALOG_DATA) public data
  	) { 
      this.currency = data.currency
      this.product = data.product
    }

  ngOnInit() {
    if(!this.currency)
    this.currency = 'INR'
      this.api.get_shipping_countries()
      .subscribe((cur:any)=>{
        this.countries = this.filterCountries(cur)
        console.log(this.countries)
        this.loader.nativeElement.style.display = 'none'
        })
    if(this.product.is_blocked)
    this.productUnavailable = true
        this.ref.detectChanges()
    
  }
  
  filterCountries(cur){
    let oldCur = []
    let countries = []
    cur.forEach((i) => {
        if(!oldCur.includes(i.country_name)){
          oldCur.push(i.country_name)
          countries.push(i)
        }
    });
    return countries
  }

  async getShipping(event){
    this.loader.nativeElement.style.display = 'flex'
    this.selectedCountry = event.value
    this.charges = await this.getShippingCharge(event.value)
    if(this.charges)
    this.loader.nativeElement.style.display = 'none'
    this.price = Math.round(((Number(this.product.price.split(' ')[0]) * 0.30)*100))/100;
    console.log("priceee", this.price)
    this.balanceAmount = Math.round((this.charges.total - this.price)*100)/100
    console.log("Product",this.product)
    this.accessCode = environment.ccav_accessCode;
    console.log(this.charges)
  }

  getShippingCharge(country){
    return new Promise((resolve, reject)=>{
      this.country = country
      console.log("currencyyyy", this.currency)
      let req = {
        country_code: country,
        currency: this.currency,
        products: [{
            weight: Number(this.product.weight),
            quantity: 1,
            total: Number(this.product.price.split(' ')[0])
          }]
      }
      this.api.get_shipping_charge(req)
      .subscribe((res:any)=>{
        if(res){
          let result = JSON.parse(res.result)
          resolve(result.response)
        }else{
          reject("No response received")
        }
      })
    })
  }

  generateOrder(){
    return new Promise((resolve, reject)=>{
      this.api.generateOrder({
        user_id: this.userId,
        products: [{
                    product_id: this.product.id,
                    quantity: 1,
                    total: this.product.price
                  }],
        currency: this.currency,
        type: 'made_to_order',
        shipping_charge: this.charges.shipping_charge,
        shipping_country: this.country,
        advance_amount: this.price,
        balance_amount: this.balanceAmount,
      })
        .subscribe((res:any)=>{
          console.log("order responsee",res)
          if(res.result == 'cannot generate order')
          reject();
          else
          resolve(res.result.id)
        })
    })
}

async createOrder(){
  try{

    this.orderId = await this.generateOrder()
    if(this.orderId !== 'Product unavailable' || this.orderId !== 'cannot generate order'){
      this.api.getOrderDetails(this.orderId).subscribe((order:any)=>{
        this.order = order
        console.log("Order: ", this.order)
        
        this.total  = this.price
        this.orderStatus = this.product
        console.log("Orderstatus", this.orderStatus)
        let data:string = `order_id=${this.orderId}&currency=${this.currency}&amount=${this.total}&language=EN`
        data = data.toString()
        this.api.getEncRequest(data)
        .subscribe((res:any) =>{
          console.log(res)
          this.encRequest = res.success;
          console.log(this.encRequest, this.accessCode)
          if(this.encRequest && this.accessCode){  
            setTimeout(()=>{
              this.form.nativeElement.submit()
            }, 2000)
          }
        }) 
      })
    }else{
      this.productUnavailable = true
      this.loader.nativeElement.style.display = 'none'
    }
  }catch(err){
    this.productUnavailable = true
    this.loader.nativeElement.style.display = 'none'
  }
}
pay(){
  this.loader.nativeElement.style.display = 'flex'
  this.createOrder()
  console.log("Start payment process")
      
}

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderSummaryRoutingModule } from './ordersummary-routing.module'
import { OrderSummaryComponent } from './order-summary.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DeliveryAddressComponent } from './delivery-address/delivery-address.component';
import { UserMessageComponent } from './user-message/user-message.component'
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap'
@NgModule({
  declarations: [OrderSummaryComponent, DeliveryAddressComponent, UserMessageComponent],
  imports: [
    CommonModule,
    OrderSummaryRoutingModule,
    FlexLayoutModule,
    MatStepperModule,
    MatTabsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    NgbAlertModule
  ],
  exports: [
    OrderSummaryComponent
  ],
})
export class OrderSummaryModule { }

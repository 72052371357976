import { Component, OnInit, TemplateRef, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiService } from '../services/api.service';
import { DataService } from '../services/data.service'
import {MatDialog} from '@angular/material';
import { environment } from '../../environments/environment'
import { LoginComponent } from '../login/login.component'
import { StaffcontactComponent } from './staffcontact/staffcontact.component'



@Component({
  selector: 'app-detailview',
  templateUrl: './detailview.component.html',
  styleUrls: ['./detailview.component.scss']
})
export class DetailviewComponent implements OnInit {
@ViewChild('loader') loader;
  imageArray: any = [];
  defaultImage: any;
  isHovered = false;
  hoveredImage: any;
  favorite: any = false;
	modalRef: any;
	productTitle: string;
	productDesc: string;
	productPrice: string;
  productId: string
	rating: number[];
	aboutUs: any;
  termsAndCond: any;
  privacyAndPolicy: any;
  returnPolicyDoc: any;
  encRequest: string;
  accessCode: string;
  id: string;
  userId: string;
  isLoggedin = 'false';
  product:any;
  type;
  currency;
  soldOut = false;
  constructor(
  	private route: ActivatedRoute,
    private router: Router,
  	private api: ApiService,
  	private modal: NgbModal,
    private data: DataService,
    public dialog: MatDialog
  	) { }

  ngOnInit() {

  	this.id = this.route.snapshot.params.id
    let cur = this.route.snapshot.params.cur
    let recentItems = localStorage.getItem('recent')
      if(recentItems){
        let itemsArray = recentItems.split(',')
        if(itemsArray.indexOf(this.id))
        recentItems = recentItems + ',' + this.id
      }
      else{
        recentItems = this.id
      }
        localStorage.setItem('recent', recentItems)
    if(cur&&!this.currency)
      this.currency = cur
      this.data.currency
        .subscribe((unit)=>{
          if(this.currency !== unit){
          this.currency = unit
          this.ngOnInit()
          }
        })
      

    this.isLoggedin = localStorage.getItem('isLoggedin') 
    if(!this.isLoggedin)
       this.isLoggedin = 'false'
    
    
    this.userId = localStorage.getItem('user')
  	this.api.getAboutUs()
      .subscribe((res:any)=>{
        this.aboutUs = environment.s3_url + 'about/' + res[0].image
      })
    this.api.getTerms()
      .subscribe((res:any)=>{
        this.termsAndCond = environment.s3_url + 'terms/' + res[0].image
      })
    this.api.getPrivacy()
      .subscribe((res:any)=>{
        this.privacyAndPolicy = environment.s3_url + 'privacy/' + res[0].image
      })
    this.returnPolicyDoc = environment.return.toString();
  	this.api.getProductDetails(this.id, this.currency)
  		.subscribe((res:any)=>{
  			res = JSON.parse(JSON.stringify(res.success[0]))
        this.product = res
        this.productId = res.product_id
  			this.productTitle = res.name
  			this.productDesc = res.description
  			this.productPrice = res.price.toString()
  			let rating  = (res.average_rating == '')? 0:parseInt(res.average_rating)
  			this.rating = Array(rating).fill(1)
        this.imageArray.push({
            image: environment.s3_url +'thumb_products/'+res.product_image
          })
        this.defaultImage = this.imageArray[0];
        if(res.is_available){
          this.type = 'Available'
        }else if(res.is_made_to_order){
          this.type = 'Made to Order'
        }else if(res.is_made_to_order_color_availability){
          this.type = 'Made according to color availability'
        }

        let images = [1,2,3,4,5,6];
        for(let img in images){
          let key = 'image_'+img
          if(res[key]){
            console.log(res.image_+img)
            this.imageArray.push({
              image: environment.s3_url +'thumb_products/'+res[key]
            })
          }
        }
        setTimeout(()=>{
          this.loader.nativeElement.style.display = 'none'
        }, 1000)
    })

    

  }
  active(){
  this.favorite = !this.favorite;
  }

  mousein(array){
    this.hoveredImage = array.image;
    this.isHovered = true;
  }

  mouseout(){
    this.isHovered = false;
  }

  changeDeafult(image){    
    this.defaultImage = image;
  }


  addToCart(){
    if(this.isLoggedin == 'false'){
        const modelRef = this.modal.open(LoginComponent)
        modelRef.result.then((res)=>{
          console.log("from login component:", res)
          if(res === 'success')
             this.ngOnInit();
        }, (reason)=>{
          return;
        })
    }else{

    if(this.product.is_available){

    let productDetails = {
      product_id: this.id,
      user_id: this.userId,
      quantity: 1
    }
    
    this.api.addToCart(productDetails).subscribe((res:any)=>{
      console.log(res);
      if(res){
        if(res.result !== 'product_count_not_less_than_total_on_hand')
        {
          this.soldOut = false
          let result = JSON.parse(res.result)
          localStorage.setItem('cart', result.id)
          this.router.navigate(['/cart'])
        }else{
          this.soldOut = true
          this.loader.nativeElement.display.none
        }
      }
    })
    }else if(this.product.is_sold){
      this.soldOut=true
    }
    else{
      let contactModel = this.dialog.open(StaffcontactComponent, {
        data: {product: this.product, currency: this.currency},
        maxHeight: '400px',
        maxWidth: '600px'
        
      });
    }
  }
}
  onModal(ref : TemplateRef<any>) {
    this.modalRef = this.modal.open(ref, { size: 'lg' });
  }

}

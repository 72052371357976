import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ListpopupComponent} from './listpopup/listpopup.component';
import { ActivatedRoute, Router } from '@angular/router' 
import { ApiService } from '../services/api.service'
import { DataService } from '../services/data.service'

import { environment } from '../../environments/environment';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";


@Component({
  selector: 'app-listview',
  templateUrl: './listview.component.html',
  styleUrls: ['./listview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListviewComponent implements OnInit {

@ViewChild('loader') loader;
products:any[] //={'name':'Product1','price':'$200','url':'assets/images/product1.png','rating':'4.4'}
p;
color:string = '';
colorFilter: boolean = false
price:any
name:any
url:any;
filter:string = '';
categoryFilter:string;
category: string;
subCategory: string = '';
currency = 'INR';
availability: boolean = false;
madeToOrder: boolean = false;
ismadeToOrderColorAvailability: boolean = false;
priceLowToHigh = 'low_to_high';
priceHighToLow = 'high_to_low';
popularity = false;
sort;
categories;
priceRange;
keyword = '';
  constructor(
    public dialog: MatDialog,
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private data: DataService
    ) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    let cur = localStorage.getItem('currency')
    console.log('currency',cur)
    if(cur)
      this.currency = cur;
    this.data.currency
      .subscribe((cur)=>{
        if(this.currency !== cur){
        this.currency = cur
        this.ngOnInit()
        }

      })
      this.data.dosearch
        .subscribe((search)=>{
          if(search){
            this.api.searchProduct(this.currency, search)
           .subscribe((res:any) => {
             this.products = res.success
             this.products.sort((a :any, b :any)=>{
              let createdOfA = new Date(a.created_at).getTime()
              let createdOfB = new Date(b.created_at).getTime()
              if(createdOfA<createdOfB)
              return 1
              if(createdOfA>createdOfB)
              return -1
              else
              return 0
            })
             this.loader.nativeElement.style.display = 'none'
             this.data.updateSearch(null)
             console.log(this.products)
           })
          }
        })
    let id = this.route.snapshot.params.id.split('=')
    if(id[0] === 'search'){
       this.keyword = id[1]
       this.api.searchProduct(this.currency, this.keyword)
           .subscribe((res:any) => {
             this.products = res.success
             this.products.sort((a :any, b :any)=>{
              let createdOfA = new Date(a.created_at).getTime()
              let createdOfB = new Date(b.created_at).getTime()
              if(createdOfA<createdOfB)
              return 1
              if(createdOfA>createdOfB)
              return -1
              else
              return 0
            })
             this.loader.nativeElement.style.display = 'none'
             console.log(this.products)
           })
    }else if(id[0] === 'broadcasted'){
        this.api.getBroadcasted(this.currency).subscribe((res:any)=>{
          this.products = res.success
          this.products.sort((a :any, b :any)=>{
            let createdOfA = new Date(a.created_at).getTime()
            let createdOfB = new Date(b.created_at).getTime()
            if(createdOfA<createdOfB)
            return 1
            if(createdOfA>createdOfB)
            return -1
            else
            return 0
          })
          this.loader.nativeElement.style.display = 'none'
        })
    }else if(id[0] === 'bestselling'){
      this.category = id[1]
      this.madeToOrder = true
      this.ismadeToOrderColorAvailability = true
      this.filterResult()
    }else if(id[0] === 'recentitems'){
      this.recentItems()
    }else{ 
      this.category = id[0]
      this.categoryFilter = 'category='+id[0]+'&currency='+this.currency

      this.listProductsByCategory(this.categoryFilter)
    }

    this.api.getCategories()
      .subscribe((res:any)=>{
        this.categories = res.result
      })

    this.filter = 'currency=INR&category='+ this.category+'&color='+this.color+'&availability='+this.availability+'&made_to_order='+this.madeToOrder+'&is_made_to_order_color_availability='+this.ismadeToOrderColorAvailability
  }


  productPop(product){
    const dialogRef = this.modalService.open(ListpopupComponent)
    dialogRef.componentInstance.product = product;
  }

  getImgUrl(img){
    return environment.s3_url + 'thumb_products/' + img
  }
    
  onProduct(id){
    this.router.navigate(['detailview', id, this.currency])
  }

  onFilter(event){
    switch (event.source.ariaLabel) {
      case "colorFilter":
        if(event.checked){
          this.colorFilter = true
        }else{
          this.colorFilter = false
          this.color = ''

        }
        break;
      case "available":
        if(event.checked){
          this.availability = true
          this.madeToOrder = false
          this.ismadeToOrderColorAvailability = false
          console.log("Available:",this.availability)
        }else{
          this.availability = false
        }

        break;
      case "madetoorder":
        if(event.checked){
          this.madeToOrder = true;
          this.ismadeToOrderColorAvailability = false
        }else{
          this.madeToOrder = false;
        }

        break;
      case "colorAvailablity": 
         if(event.checked){
           this.ismadeToOrderColorAvailability = true
           this.madeToOrder = false;
        }else{
          this.ismadeToOrderColorAvailability = false
        }
        break;
      case "sortByPriceLowToHigh":
        if(event.value = 'low_to_high'){
        this.priceRange = 'low_to_high'
      } else{
        this.priceRange = ''
       }    

        break;
      case "sortByPriceHighToLow":
      if(event.value = 'high_to_low'){
        this.priceRange = 'high_to_low'
      }else{
        this.priceRange = ''
        break;
      }
      case "sortByPopularity":
        if(event.checked){ 
       this.sort = this.categoryFilter + '&popularity=popularity'
        this.listProductsByCategory(this.sort)
        }else{
          this.listProductsByCategory(this.categoryFilter)
        }
        break;
      
      default:
        // code...
        break;
    }
    console.log(event)
  }

  catFilter(event){
     console.log("Category: ", event.value)
        let data = event.value.split('-');
        console.log("categoriesss",data)
        if(data.length > 1){
          this.category = data[0]
          this.subCategory = data[1]
        }else{
          this.category = data[0]
          this.subCategory = ''
        }
        console.log("Categoriess", this.category, this.subCategory)
        this.filterResult()
  }

  listProductsByCategory(filter){
     this.api.getProductCategory(filter)
      .subscribe((_res: any)=>{
        console.log(_res)
          this.products = _res.success
          this.products.sort((a :any, b :any)=>{
            let createdOfA = new Date(a.created_at).getTime()
            let createdOfB = new Date(b.created_at).getTime()
            if(createdOfA<createdOfB)
            return 1
            if(createdOfA>createdOfB)
            return -1
            else
            return 0
          })
          console.log(this.products)
          setTimeout(()=>{
            this.loader.nativeElement.style.display = 'none'
          }, 1000)
        })
  }
  filterResult(){
    this.loader.nativeElement.style.display = 'flex'
    let itemColor = this.color
    if(this.color.length == 0){
      itemColor = "!=null"
    }
    this.filter = 'currency='+ this.currency +'&category='+ this.category+'&color='+itemColor+'&availability='+this.availability+'&made_to_order='+this.madeToOrder+'&is_made_to_order_color_availability='+this.ismadeToOrderColorAvailability+'&price_range='+this.priceRange + '&sub_category=' + this.subCategory
     this.api.filterProductsInCategory(this.filter)
      .subscribe((_res: any)=>{
          this.products = _res.success
          // this.products.sort((a :any, b :any)=>{
          //   let createdOfA = new Date(a.created_at).getTime()
          //   let createdOfB = new Date(b.created_at).getTime()
          //   if(createdOfA<createdOfB)
          //   return 1
          //   if(createdOfA>createdOfB)
          //   return -1
          //   else
          //   return 0
          // })
          setTimeout(()=>{
            this.loader.nativeElement.style.display = 'none'
          }, 1000)
        })
  }

  recentItems(){
    let items = localStorage.getItem('recent')
    if(items){
      let recentItems = items.split(',')
      let recentProducts = []
      recentItems.forEach((item)=>{
        this.api.getProductDetails(item, this.currency)
        .subscribe((prod:any)=>{
          recentProducts.push(prod.success[0])
        })
        debugger;
        this.products = recentProducts;
        this.products.sort((a :any, b :any)=>{
          let createdOfA = new Date(a.created_at).getTime()
          let createdOfB = new Date(b.created_at).getTime()
          if(createdOfA<createdOfB)
          return 1
          if(createdOfA>createdOfB)
          return -1
          else
          return 0
        })
        setTimeout(()=>{
          this.loader.nativeElement.style.display = 'none'
        }, 1000)
      })
    }else{
      this.loader.nativeElement.style.display = 'none'

    }
  }

  }
  






<div id="staff_popup">
  
  <div class="modal-header">
  <h4 class="modal-title head" *ngIf="product.is_made_to_order_color_availability">Please contact the designer to make a
    purchase of this item</h4>
    <h4 class="modal-title" *ngIf="product.is_made_to_order">MADE TO ORDER</h4>
    <button type="button" class="close" aria-label="Close" (click)="dialogRef.close('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="product.is_made_to_order_color_availability" fxLayout="row wrap" fxFlex="100">
      <p>
          Please Contact our sales team to make a purchase of this item. <br>
          Email : contact@tazzels3.com   <br>
          Contact No : 00971557167369 (WhatsApp)
      </p>
    </div>

    <div *ngIf="productUnavailable && !product.is_made_to_order_color_availability"  fxLayout="row wrap" fxFlex="100">

      <p >
        This product is currently unavailable. Please try again after sometime!
      </p>
    </div>
    
    <div *ngIf="product.is_made_to_order" fxLayout="row wrap" fxFlex="100">
      <div fxLayout="row wrap" fxFlex="100">
        <P>
          Items with status MADE TO ORDER are made on your order.
          To place the order you need to pay 30% of total cost of the item as advance payment.
          When the item is ready for dispatch you will be informed.
          Item will be dispatched to you as soon as you do the payment of balance outstanding amount.
          The shipping cost will be added only with the final payment.
        </P>
        
        <h5 fxFlex="100" class="head">Select country to ship</h5>
        <mat-form-field fxFlex="100" appearance="outline">
        <mat-select placeholder="Select your country" (selectionChange)="getShipping($event)">
          <mat-option *ngFor="let country of countries; let i = index" [value]="country.country_code">
            {{country.country_name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="details_sec" *ngIf="charges && !charges.error && !productUnavailable">
        <p> Shipping charge: {{charges.shipping_charge}} {{currency}}</p> <br />
        <p>
          Advance payment: {{price}} {{currency}} <br />
          Balance to be paid: {{balanceAmount}} {{currency}}
        </p>
        <br/>
        <p *ngIf="productUnavailable">
          This product is currently unavailable. Please try again after sometime!
        </p>
      </div>
      <div class="details_sec" *ngIf="charges?.error">
        <p>{{charges.error}}</p>  
      </div>
    </div>
  </div>
</div>
<div *ngIf="product.is_made_to_order && charges && !charges.error" class="modal-footer btn_sec">
  <button type="button" class="btn btn-outline-dark" (click)="dialogRef.close('Close click')">Close</button>
  <button *ngIf="!productUnavailable" type="button" class="btn btn-outline-dark" (click)="pay()">Proceed to payment</button>
</div>
<form #form ngNoForm id="nonseamless" method="post" name="redirect"
action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction">
<input type="hidden" id="encRequest" name="encRequest" value="{{encRequest}}">
<input type="hidden" name="access_code" id="access_code" value="{{accessCode}}">
</form>

<div class="loader" #loader>
  <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
</div>
</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListviewComponent } from './listview.component';
import { ListviewRoutingModule } from './listview-routing.module'
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDialogModule} from '@angular/material/dialog';
import { ListpopupComponent } from './listpopup/listpopup.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {NgxPaginationModule} from 'ngx-pagination';

@NgModule({
  declarations: [ListviewComponent, ListpopupComponent],
  exports: [
    ListviewComponent
  ],
  imports: [
    CommonModule,
    ListviewRoutingModule,
    FlexLayoutModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatDialogModule,
    MatCheckboxModule,
    MatInputModule,
    MatRadioModule,
    FormsModule,
    MatButtonModule,
    NgxPaginationModule
  ],
  entryComponents: [
    ListpopupComponent,
  ],
})
export class ListviewModule { }

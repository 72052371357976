import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material';
import { TreasurypopComponent } from './treasurypop/treasurypop.component';
import { ApiService } from '../services/api.service'
import { environment } from '../../environments/environment'


@Component({
  selector: 'app-treasury',
  templateUrl: './treasury.component.html',
  styleUrls: ['./treasury.component.scss']
})
export class TreasuryComponent implements OnInit {

products: any = [];
  constructor(
    public dialog: MatDialog, 
    public api: ApiService
    ) { }

  ngOnInit() {

   this.api.getTreauries()
     .subscribe((res: any)=>{
       this.products = res.map((item:any)=>{
         return  {
             image : environment.s3_url + 'thumb_treasuries/' + item.image,
              text : item.title,
              id: item.id
              }

       })
     })

    
  }

  openDialog(product) {
    const dialogRef = this.dialog.open(TreasurypopComponent,  {
    
      data: {
        dataKey: product
      }
      
    })
    console.log(product);
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result`);
    });
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { ApiService } from '../services/api.service'
import { environment } from '../../environments/environment'
@Component({
  selector: 'app-order-status',
  templateUrl: './order-status.component.html',
  styleUrls: ['./order-status.component.scss']
})
export class OrderStatusComponent implements OnInit {
  p;
  @ViewChild('form') form
  @ViewChild('loader') loader
  collection: any = [];
  orderId;
  currency = 'INR'
  order;
  address
  productImgUrl = environment.s3_url + 'thumb_products/'
  encRequest
  accessCode = environment.ccav_accessCode
  selectedCountry;
  charges
  countries
  constructor(
      private route: ActivatedRoute,
      private api: ApiService
    ) { }

  ngOnInit() {
      this.orderId = this.route.snapshot.params.orderId
      console.log(this.orderId)
      this.api.getOrderDetails(this.orderId)
      .subscribe((res:any)=>{
        
        this.loader.nativeElement.style.display = 'none'
        if(res.status == "Success" || res.status == "order_placed")
        this.currency = res.orderInfo.currency
        this.order = res
        console.log("ordersss",this.order)
        console.log(this.order)
        if(res.type === 'made_to_order'){
          if(res.status == 'Success')
          this.order.status = 'Order placed'
          if(res.order_status == 'ready_to_dispatch')
          this.order.status = 'Ready to dispatch'        
          
        }
        this.address = res.orderInfo.delivery_name + ', ' + res.orderInfo.delivery_address + ', ' + res.orderInfo.delivery_city + ',' + res.orderInfo.delivery_state + ', ' +  res.orderInfo.delivery_zip + ', ' + res.orderInfo.delivery_country
        res.products.forEach((prod:any)=>{
          this.api.getProductDetails(prod.product_id, res.orderInfo.currency)
          .subscribe((prodDetails: any)=>{
            this.collection.push(prodDetails.success[0])
          })
        })
        console.log(res)
        this.ecryptReq()
        })
  }

  pay(){
    this.loader.nativeElement.style.display = 'flex'
    console.log("Start payment process")
        if(this.encRequest && this.accessCode){  
          setTimeout(()=>{
            this.form.nativeElement.submit()
          }, 2000)
        }
  }

  ecryptReq(){
    let data:string = `order_id=${this.orderId}&currency=${this.currency}&amount=${this.order.balance_amount}&language=EN`
    data = data.toString()
    this.api.getEncRequest(data)
      .subscribe((res:any) =>{
        this.loader.nativeElement.style.display = 'none'
        console.log(res)
        this.encRequest = res.success;
      console.log(this.encRequest, this.accessCode)
      }) 
  }



}

import { Component, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import { Inject } from '@angular/core';

@Component({
  selector: 'app-treasurypop',
  templateUrl: './treasurypop.component.html',
  styleUrls: ['./treasurypop.component.scss']
})
export class TreasurypopComponent implements OnInit {
  constructor( @Inject(MAT_DIALOG_DATA) public data: any) { }



  ngOnInit() {

    console.log(this.data);
  }

}

<div fxLayout="row wrap" fxFlex="100" id="cart_page">
    <p  fxFlex="100" *ngIf="successMessage" class="alert" >
        <ngb-alert [dismissible]="true" [type]="messageType" (close)="successMessage = null">
          {{successMessage}}
        </ngb-alert>
      </p>
  <div fxLayout="row wrap" fxFlex="100" class="detail_sec_outer">
    <h2 fxFlex="100">
      My Cart
    </h2>
    <mat-divider fxFlex="100"></mat-divider>

    <div fxFlex="100" fxLayout="row wrap">
      <div fxHide fxFlexOrder.gt-xs="2" fxFlexOrder.xs="1" fxLayout="row wrap" fxFlex.gt-xs="50" fxFlex.xs="100" class="image_sec">
        <div fxHide class="image_view">
          <img [src]="isHovered?hoveredImage:defaultImage?.image" />
        </div>
        <div fxHide fxFlex="25" class="image_preview_margin" fxLayout="row wrap" fxLayoutAlign="center center" *ngFor="let image of imageArray">
          <span class="image_preview" [ngClass]="{'border_active':defaultImage.image == image.image}">
            <img [src]="image.image" (mouseover)="mousein(image)" (mouseout)="mouseout()" 
            (touchstart)="mousein(image)" (touchend)="mouseout()" (click)="changeDeafult(image)" draggable="false"/>
          </span>
        </div>
      </div>
      <div fxFlexOrder.gt-xs="1" fxFlexOrder.xs="2" fxLayout="row wrap" fxFlex.gt-xs="50" fxFlex.xs="100" class="detail_sec">
        <div fxFlex="100" fxLayout="row wrap" class="inner_detail_view">

          <div fxFlex="100"  fxLayout="row wrap" *ngFor="let item of cart; let i = index">
            <div *ngIf="item.quantity > 0">
           <div fxFlex="100"  fxLayout="row wrap" >
          <h3 fxFlex="100">{{item.Productdata.name}}</h3>
          <p fxFlex="100">{{item.Productdata.description}}</p>
          <p fxFlex="100" class="maid_order">Quantity</p>
  
          <div fxFlex="100" class="btn_sec_quantity">
              <button class="incriment" mat-button (click)="inc(i)">+</button>
              <span>{{item.quantity}}</span>
              <button class="decriment" mat-button (click)="dec(i)" >-</button>
            </div>
            <div fxFlex="100">
              <span class="price">{{item.currency}} {{item.total}}</span>
            </div>

           </div> 

           <mat-divider class="order-sec" fxFlex="100"></mat-divider>

          </div>
          </div>
          <div fxFlex="100" fxLayout="row wrap" class="order-summery">
            <div fxFlex="50" fxLayout="column">
              <h4>Cart Total</h4>
          <!--     <p>Sub total <span class="value">{{itemTotal}}</span></p>
              <p>Shipping <span class="value">{{shippingCharge}}</span></p> -->
              <p>total <span class="value">{{itemTotal}} {{currency}}</span></p>
            </div>

            </div>
  
          
  
          
  
        </div>
  
  
      </div>
    </div>
    <div class="btn_sec">
        <button mat-button (click)="saveCart()" >Save cart</button>
        <button mat-button (click)="checkOut()" [disabled]="cart.length === 0" >Proceed to checkout</button>
      </div>

    
  </div>






<!-- footer -->
<!-- footer -->
</div>

<div class="loader" #loader>
    <svg>
        <g>
          <path d="M 50,100 A 1,1 0 0 1 50,0"/>
        </g>
        <g>
          <path d="M 50,75 A 1,1 0 0 0 50,-25"/>
        </g>
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" style="stop-color:#5f1715;stop-opacity:1" />
            <stop offset="100%" style="stop-color:#f7f7e6;stop-opacity:1" />
          </linearGradient>
        </defs>
      </svg>
</div>

<div fxLayout="row wrap" fxFlex="100" id="forgetpassword"> 
<div fxHide.xs fxHide.sm fxFlex="33"></div>
<div fxFlex.gt-xs="33" fxFlex.sm="100" fxFlex.xs="100" fxLayout="row wrap">
  <h3 fxFlex="100">Forgot Password</h3>
  <p fxFlex="100">New password will be send to this email.</p>

    <p fxFlex="100" *ngIf="alert">
      <ngb-alert [type]="alert.type" (close)="alert = null">{{ alert.message }}</ngb-alert>
    </p>
  <mat-form-field fxFlex="100" appearance="outline">
      <input matInput [(ngModel)]="email" placeholder="Email Id">
    </mat-form-field>
    <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
      <button *ngIf="!sent" class="send" (click)="sendPassword()" mat-button>Send</button>
      <button *ngIf="sent" class="send" (click)="sendPassword()" mat-button>Resend</button>
    </div>
</div>
<div fxHide.xs fxHide.sm fxFlex="33"></div>
</div>

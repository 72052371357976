import { Component, OnInit, ElementRef, ViewChild, NgZone } from '@angular/core';
import { Router } from '@angular/router'
import { ApiService } from '../services/api.service'
import { DataService } from '../services/data.service'
import { environment } from '../../environments/environment'
import { MatDialog } from '@angular/material';
import { CountryPopupComponent } from './country-popup/country-popup.component';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  @ViewChild('form') form: ElementRef;
  @ViewChild('loader') loader
  imageArray: any = [];
  defaultImage: any;
  isHovered = false;
  hoveredImage: any;
  successMessage = null
  messageType = 'success'
  userId;
  cart: any = [];
  itemTotal: number = 0;
  shippingCharge = 0;
  total;
  cartId;
  orderId;
  newCart;
  currency;
  shippingDetails;
  constructor(
    private api: ApiService,
    private router: Router,
    private zone: NgZone,
    public dialog: MatDialog,
    private data: DataService
  ) { }

  ngOnInit() {
    this.userId = localStorage.getItem('user');

    this.cartId = localStorage.getItem('cart');

    this.currency = localStorage.getItem('currency');
    if (!this.currency)
      this.currency = 'INR'
    this.data.currency
      .subscribe((unit) => {
        if (this.currency !== unit) {
          this.currency = unit
          this.ngOnInit()
        }
      })
    this.api.getCart(this.userId, this.currency)
      .subscribe((res: any) => {

        this.cart = res.result


        this.newCart = this.cart.map((prod: any) => {
          if (prod.quantity > 0) {
            return {
              product_id: prod.product_id,
              user_id: prod.user_id,
              quantity: prod.quantity
            }
          }
        })
        this.newCart = this.newCart.filter((item) => {
          if (item === undefined)
            return false
          else
            return true;
        })
        this.updateView()

        setTimeout(() => {
          this.loader.nativeElement.style.display = 'none'
        }, 1000)
      })


    this.imageArray = this.cart.map((prod: any) => {
      return {
        image: environment.s3_url + 'thumb_products/' + prod.Productdata.product_image
      }
    })

    this.defaultImage = this.imageArray[0];
  }





  mousein(array) {
    this.isHovered = true;
    this.hoveredImage = array.image;
  }

  mouseout() {
    this.isHovered = false;
  }

  changeDeafult(image) {
    console.log(image);
    this.defaultImage = image;
  }


   async checkOut() {
    await this.saveCart()

    const dialogRef = this.dialog.open(CountryPopupComponent, {
      data: { cart: this.cart, currency: this.currency }
    });


    dialogRef.afterClosed().subscribe( (result: any) => {
      this.loader.nativeElement.style.display = 'flex'
      if (result) {

        this.shippingDetails = result
        let shipping = JSON.stringify(this.shippingDetails)
        localStorage.setItem('shipping', window.btoa(shipping));
        this.router.navigate(['ordersummary', this.userId, result.shipping_country])

      } else {
        this.loader.nativeElement.style.display = 'none'
      }
    }, error => {
      this.loader.nativeElement.style.display = 'none'
    });

  }

  dec(item) {
    this.newCart[item].quantity -= 1;
    this.cart[item].quantity -= 1; //for display
    if (this.newCart[item].quantity == 0) {
      this.newCart.splice(item, 1)
      this.cart.splice(item, 1)
    }
    this.updateView()
  }

  inc(item) {
    if (this.cart[item].quantity < this.cart[item].Productdata.total_on_hand) {
      this.newCart[item].quantity += 1;
      this.cart[item].quantity += 1; //for display         
    } else {
      this.newCart[item].quantity = this.cart[item].Productdata.total_on_hand - 1
    }
    this.updateView()
  }

  saveCart() {
    return new Promise((resolve, reject)=>{
      this.api.removeCart(this.userId)
      .subscribe((res) => {
        if (res) {
          let cartSave = true
          this.newCart.forEach((item:any)=>{

            this.api.addToCart(item)
            .subscribe((res) => {
              if (res) {
                cartSave = true
              }
            }, (err)=>{
              cartSave = false
            })
          })

          if(cartSave){
            this.successMessage = 'Cart saved successfully!'
            setTimeout(() => {
              this.successMessage = null
            }, 3000)
            resolve('cart saved')
          }else{
            this.successMessage = 'Cart cannot be saved!'
            this.messageType = 'danger'
            reject('cart not saved')

          }
        }
      })
    })
  }




  updateView() {
    this.zone.run(() => {
      this.itemTotal = 0;
      this.cart.forEach((item: any, index) => {
        if (item.quantity > 0) {
          item.total = item.Productdata.price * item.quantity
          if (this.newCart[index]) {
            this.newCart[index].total = item.total
          }
          this.itemTotal += item.total;
          console.log(item.total)
        }
      })
    })
    // this.total = this.itemTotal + this.shippingCharge
  }


}

<div class="modal-header" *ngIf="product.url != undefined">
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
   <div class="products" >
    <div >
        <span class="image_list">
        <img [src]="product.url"/>
        </span>    
        <p  class="name">
            {{product.name}}
        </p>
        <p class="price">
            {{product.price}}
        </p>
        <p  class="rating">
            {{product.average_rating}}
        </p>
    </div>
  </div>
</div>
<div class="modal-footer">
   <button type="button" class="btn btn-outline-dark" (click)="close()">Buy Now</button>
</div>



<!-- 
<h1 mat-dialog-title></h1>
<div mat-dialog-content>
<div class="products" fxLayout="row wrap" fxFlex="100">
  <div fxFlex="100">
    <span class="image_list">
        <img [src]="data.url"/>
    </span>
    
     <p fxFlex="100" class="name">
        {{data.name}}
     </p>
     <p fxFlex="100" class="price">
        {{data.price}}
     </p>
     <p fxFlex="100" class="rating">
        {{data.average_rating}}
     </p>
     
    
  </div>
  
</div>
 
</div>
 -->
<nav id="header_sec" class="navbar navbar-expand-lg navbar-light bg-light">

  <button (click)="menuopen()" class="navbar-toggler" type="button" data-toggle="collapse"
    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
    aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
    <span class="menu">
      <img src="assets/images/menu.png">
    </span>
  </button>


  <a fxHide.gt-xs fxShow.sm fxShow.xs class="navbar-brand" href="home">
    <span class="logo">
      <img src="assets/images/logo.png" />
    </span>
  </a>


  <div *ngIf="isClicked" fxLayout="row wrap" fxFlex="100" class="collapse navbar-collapse" id="navbarSupportedContent">

    <div fxFlex.gt-xs="5" fxFlex.sm="20" class="home" fxFlex.xs="20">
      <a class="navbar-brand" href="home"> Home </a> </div>
    <div fxFlex.gt-xs="25" fxFlex.sm="80" fxFlex.xs="80" fxLayoutAlign.sm="end center" fxLayout="row wrap" class="form-inline my-2 my-lg-0">
      <input class="form-control mr-sm-2" type="search" placeholder="Search" #searchBox
        (keydown.enter)="search($event, searchBox)" aria-label="Search">
    </div>
    <div fxFlex.gt-xs="33" fxHide.sm fxHide.xs fxLayoutAlign="center center" fxLayout="row wrap">
      <a class="navbar-brand" href="home">
        <span class="logo">
          <img src="assets/images/logo.png" />
        </span>
      </a>
    </div>
    <div fxFlex.gt-xs="33" fxFlex.sm="100" fxFlex.xs="100" fxLayout="row wrap">

      <ul fxLayout="row wrap" fxFlex.xs="100" class="navbar-nav menusec">
        <li fxFlex.gt-sm="60" fxFlex.sm="60" fxFlex.xs="100" class="nav-item">

          <mat-form-field fxFlex.gt-xs="100" fxFlex.sm="100">
            <mat-label>Select currency</mat-label>
            <mat-select (selectionChange)="onCurrency($event.value)" [value]="currency">
              <mat-option *ngFor="let cur of currencies" [value]="cur.code">{{cur.country_name}}  ({{cur.code}})</mat-option>
              
            </mat-select>
          </mat-form-field>
        </li>


        <li *ngIf="isLoggedIn == null || isLoggedIn == 'false'" fxFlex.gt-sm="20" fxFlex.sm="20" fxFlex.xs="50" class="nav-item">
          <a *ngIf="isLoggedIn == null || isLoggedIn == 'false'" (click)="onLogin()">Login</a>
        </li>
        <li *ngIf="isLoggedIn == null || isLoggedIn == 'false'" fxFlex.gt-sm="20" fxFlex.sm="20" fxFlex.xs="50" class="nav-item">
          <a routerLink="/signup" *ngIf="isLoggedIn == null || isLoggedIn == 'false'">
              Sign up
          </a>
        </li>
       
        <li *ngIf="isLoggedIn == 'true'" fxFlex.sm="20" fxFlex.xs="20" (click)="profile()" class="nav-item profile">
          <a class="navbar-brand home" style="cursor: pointer">
            Menu
          </a>
          <div class="logout_pop" *ngIf="ProfileSec">
            <p *ngIf="isLoggedIn == 'true'" routerLink="/profile">Profile</p>
            <p *ngIf="isLoggedIn == 'true'" (click)="gotoCart()">Cart</p>
            <p *ngIf="isLoggedIn == 'true'" (click)="gotoMyorders()">My orders</p>
            <p *ngIf="isLoggedIn == 'true'" routerLink="listview/recentitems">Recently viewed</p>  
            <p *ngIf="isLoggedIn == 'true'" (click)="logout()">Logout</p>
            <!-- <p *ngIf="isLoggedIn == null || isLoggedIn == 'false'" (click)="onLogin()">Login</p>
            <p routerLink="/signup" *ngIf="isLoggedIn == null || isLoggedIn == 'false'">
                Sign up
            </p> -->
           </div>
          </li>
      </ul>
    </div>
  </div>

</nav>
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataService } from '../../services/data.service';
import { ApiService } from '../../services/api.service'
import { FormGroup, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap'
@Component({
  selector: 'app-delivery-address',
  templateUrl: './delivery-address.component.html',
  styleUrls: ['./delivery-address.component.scss']
})
export class DeliveryAddressComponent implements OnInit {
  orderId
  country
  error = false
  constructor(
    private api: ApiService,
    private dataSrv: DataService,
    public dialogRef: MatDialogRef<DeliveryAddressComponent>,
      @Inject(MAT_DIALOG_DATA) public data
    ) { 
      this.orderId = data.order
      this.country = data.country
    }
    address = new FormGroup({
      delivery_name: new FormControl('', Validators.required),
      delivery_address: new FormControl('', Validators.required),
      delivery_city: new FormControl('', Validators.required),
      delivery_state: new FormControl('', Validators.required),
      delivery_country: new FormControl('UAE', Validators.required),
      delivery_tel: new FormControl('', Validators.required)
      
    })

  ngOnInit() {
    console.log(this.country)
  }

  continue(){
    if(this.address.valid){
      this.dialogRef.close(this.address.value)
    }else{
      this.error = true
      setTimeout(()=>{
        this.error = false
      }, 6000)
    }
  }
}

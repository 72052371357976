import { Component, OnInit, TemplateRef, ElementRef, ViewChild } from "@angular/core";
import { ApiService } from "../services/api.service";
import { environment } from "../../environments/environment";
import { NgbModal, NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { HomePopupComponent } from './home-popup/home-popup.component';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  modalRef;
  interval;
  constructor(private api: ApiService, private modal: NgbModal, private router: Router, public dialog: MatDialog) { }
  @ViewChild('loader') loader
  toolTip = false;
  public imagesUrl: any[];
  public productimgUrl: any[];
  public testimonialsIndex: any = 0;
  testimonialsArray: any[];
  testimonialsArrayShow: any;
  productSLider: any[];
  banners: any[];
  broadcastedItems: any[];
  topSellingItems: any[];
  categories: any[];
  aboutUs: any;
  termsAndCond: any;
  privacyAndPolicy: any;
  returnPolicyDoc: any;
  treasuryItems: any = [];
  teasers: any = []
  ngOnInit() {
    this.api.getAboutUs()
      .subscribe((res: any) => {
        this.aboutUs = environment.s3_url + 'about/' + res[0].image
      })
    this.api.getTerms()
      .subscribe((res: any) => {
        this.termsAndCond = environment.s3_url + 'terms/' + res[0].image
      })
    this.api.getPrivacy()
      .subscribe((res: any) => {
        this.privacyAndPolicy = environment.s3_url + 'privacy/' + res[0].image
      })
    this.returnPolicyDoc = environment.return.toString();
    this.api.productList().subscribe((items: any) => {
      this.teasers = items.products_list_front.teaser;
      setTimeout(()=>{
        this.loader.nativeElement.style.display = 'none'
      }, 1000)
      // this.teasers.forEach((teaser:any) => {
      //    teaser.time = this.timeConversion(teaser.time)
          
      // });
      this.banners = this.teasers.map((teaser: any) => {
        return environment.s3_url + "thumb_teasers/" + teaser.image;
      });
      const sampleTestimonial = [
        { heading: 'Ashwathy Mukundan',
          content: '"Last month was my marriage...most of my outfits for pre-wedding and post wedding shot was tazzels3 product...everyone loved it..thank you"'
        },
        {
          heading: 'Gayathri Mahesh',
          content: '"Hiii Nimmy, recieved ma parcel,it looks better than the pics i must say, loved the colour sooo much. Thanks a lot”'
        }
      ]

      this.testimonialsArray = items.products_list_front.review.map(
        (review: any) => {
          return {
            heading: review.title,
            content: review.description
          };
        }
      );
      if(this.testimonialsArray.length == 0)
        this.testimonialsArray = sampleTestimonial
      let element = document.getElementById("conatiner");
      element.classList.add("left_slide");
      this.testimonialsArrayShow = this.testimonialsArray[
        this.testimonialsIndex
      ];







      var topSelling = items.products_list_front.top_selling;




      if (topSelling.length > 0) {
        this.topSellingItems = topSelling.map((item: any, i: number) => {
          let name = Object.keys(item)[0]
          if (item[name].length>0) {
            var img = item[name].map((_i: any) => {
              return environment.s3_url + 'thumb_products/' + _i.product_image
            })
          }
          if (item[name].length > 0) {
            var categoryId = (item[name][0])?item[name][0].category_id:null
          }

          return { label: name, images: img, category: categoryId }
        });
      }

      this.broadcastedItems = items.products_list_front.broadcasted_items.map(
        (item: any) => {
          return {
            image: environment.s3_url + "thumb_products/" + item.product_image,
            label: (item.name == '')? item.product_id: item.name
          } 
        }
      );

      this.categories = items.products_list_front.categories
      this.treasuryItems = items.products_list_front.treasury.map((item: any, index) => {
        if (index >= 5) {
          return
        }
        else {

          return {
            image: environment.s3_url + 'thumb_treasuries/' + item.image
          }
        }
      })

    });

    this.interval = setInterval(() => {
      this.next()
    }, 5000)
   
  }

  tooltip(){
    this.toolTip = !this.toolTip;
  }

  timeConversion(time){
    let offset = new Date().getTimezoneOffset()
    let hours = parseInt(time.split(':')[0])
    let mins = parseInt(time.split(':')[1])
    let totalmins = ((hours*60)+mins)-offset
    let newHrs = (Math.floor(totalmins/60)<10)? '0'+(Math.floor(totalmins/60)).toString():Math.floor(totalmins/60)
    let newMins = ((totalmins%60)<10)?'0'+(totalmins%60).toString():totalmins%60
    return newHrs + ':' + newMins
  }


  previous() {
    var element = document.getElementById("conatiner");
    element.classList.remove("left_slide");
    if (element.classList.contains("right_slide")) {
      element.classList.remove("right_slide");
    }
    setTimeout(() => {
      this.testimonialsIndex--;
      if (this.testimonialsIndex > 0) {
      } else {
        this.testimonialsIndex = this.testimonialsArray.length - 1;
      }
      this.testimonialsArrayShow = this.testimonialsArray[
        this.testimonialsIndex
      ];
      element.classList.add("right_slide");
    }, 500);
    // element.classList.remove("right_slide");
  }
  next() {
    var element = document.getElementById("conatiner");
    element.classList.remove("right_slide");
    if (element.classList.contains("left_slide")) {
      element.classList.remove("left_slide");
    }
    setTimeout(() => {
      this.testimonialsIndex++;
      if (this.testimonialsIndex <= this.testimonialsArray.length - 1) {
      } else {
        this.testimonialsIndex = 0;
      }
      this.testimonialsArrayShow = this.testimonialsArray[
        this.testimonialsIndex
      ];
      element.classList.add("left_slide");
    }, 500);
  }

  previewslide() {
    this.productSLider.push(this.productSLider.shift());
  }

  nextslide() {
    var element = document.getElementById("conatiner2");
    element.classList.remove("right_slide");
    if (element.classList.contains("left_slide")) {
      element.classList.remove("left_slide");
    }
    setTimeout(() => {
      this.productSLider.unshift(this.productSLider.pop());

      element.classList.add("left_slide");
    }, 500);
  }

  onModal(ref: TemplateRef<any>) {
    this.modalRef = this.modal.open(ref, { size: 'lg' });
  }

  getImageArray(img) {
    let url;
    if (typeof img == 'string')
      url = environment.s3_url + 'thumb_categories/' + img
    return [url]
  }
  gotoProducts(id) {
    clearInterval(this.interval)
    this.router.navigate(['listview', id])
  }

  gotoBestSelling(id){
    clearInterval(this.interval)
    this.router.navigate(['listview', 'bestselling='+id])
  }
  gotoTreasury() {
    clearInterval(this.interval)
    this.router.navigate(['treasury']);
  }

  gotoBroadcasted() {
    clearInterval(this.interval)
    this.router.navigate(['listview', 'broadcasted'])
  }



}

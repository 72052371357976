import { Component, OnInit, Input } from '@angular/core';
import {Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { environment } from '../../../environments/environment'
@Component({
  selector: 'app-listpopup',
  templateUrl: './listpopup.component.html',
  styleUrls: ['./listpopup.component.scss']
})
export class ListpopupComponent implements OnInit {

  constructor(
  	private active: NgbActiveModal
  	// public dialogRef: MatDialogRef<ListpopupComponent>,
   //  @Inject(MAT_DIALOG_DATA) public data: any
    ) { }
  @Input('product') product;

  ngOnInit() {

  	if(this.product != undefined){
  		this.product.url = environment.s3_url + 'thumb_products/' + this.product.product_image
  	}
    
  }
  close(){
  	this.active.close()
  }

}

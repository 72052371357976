import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { ApiService } from '../services/api.service'
import { environment } from '../../environments/environment'
import { MatDialog } from '@angular/material'
import { DeliveryAddressComponent } from './delivery-address/delivery-address.component';
import { UserMessageComponent } from './user-message/user-message.component'
@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrderSummaryComponent implements OnInit {
  @ViewChild('form') form;
  @ViewChild('stepper') stepper;
  @ViewChild('loader') loader;
  isLinear;
  firstFormGroup;
  secondFormGroup;
  hide = true;
  orderStatus: any = [];
  cities: any = [];
  states: any = [];
  orderId;
  order;
  products: any = [];
  encRequest
  accessCode;
  shippingCharge = 0;
  total = 0;
  country;
  userId;
  cartItems;
  shippingDetails
  currency = localStorage.getItem('currency')
  orderValue = 0;
  orderType = 'Normal'
  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit() {
    this.userId = this.route.snapshot.params.userId
    this.country = this.route.snapshot.params.country
    if (!this.currency)
      this.currency = 'INR'
    this.accessCode = environment.ccav_accessCode;
    this.api.getCart(this.userId, this.currency)
      .subscribe((res: any) => {
        console.log("cart responseeee",res)
        this.loader.nativeElement.style.display = 'none'
        this.cartItems = res.result.map((prod: any) => {
          if (prod.quantity > 0) {
            return {
              product_id: prod.product_id,
              user_id: prod.user_id,
              quantity: prod.quantity,
              total: prod.total
            }
          }
        }).filter((item) => {
          if (item === undefined)
            return false
          else
            return true;
        })
        this.cartItems.forEach((item:any)=>{
          this.orderValue += item.total
        })
        console.log("cart itemsssss", this.cartItems)
        this.shippingDetails = JSON.parse(window.atob(localStorage.getItem('shipping')));
        this.order = {
          user_id: this.userId,
          products: this.cartItems,
          shipping_country: this.shippingDetails.shipping_country,
          shipping_charge: <number>this.shippingDetails.shipping_charge,
          total: this.shippingDetails.total,
          currency: this.currency,
          type: 'Normal'
        }
        console.log("Orderrr",this.order)
        this.shippingCharge = this.shippingDetails.shipping_charge
        this.total = this.shippingDetails.total
        this.order.products.forEach((prod: any) => {
          // this.total += prod.total
          this.api.getProduct(prod.product_id).subscribe((prodDetail: any) => {
            console.log(prodDetail)
            let product = {
              image: environment.s3_url + 'thumb_products/' + prodDetail.product_image,
              code: prodDetail.product_id,
              item: prodDetail.name,
              size: prodDetail.weight,
              quantity: prod.quantity,
              price: prod.total
            }
            this.products.push(product);
          })
          // this.total += this.shippingCharge
          this.orderStatus = this.products
          console.log("Orderstatus", this.orderStatus)
        })


      })
    }

  generateOrder() {
          return new Promise((resolve, reject) => {

            this.api.generateOrder(this.order)
              .subscribe((res: any) => {
                console.log("order", res)
                if (res.result)
                  resolve(res.result.id)
                else
                  reject(res);
              })
          })
        }

 async pay(){
          this.loader.nativeElement.style.display = 'flex'
          this.orderId = await this.generateOrder()
          if(this.orderId){

            let data:string = `order_id=${this.orderId}&currency=${this.currency}&amount=${this.total}&language=EN`
            console.log(data)
            data = data.toString()
            this.api.getEncRequest(data)
            .subscribe((res:any) =>{
              console.log(res)
              this.encRequest = res.success;
              console.log(this.encRequest, this.accessCode)
              console.log("Start payment process")
              if(this.encRequest && this.accessCode){
                setTimeout(() => {
                  this.form.nativeElement.submit()
                }, 2000)
              }
            }) 
          }else{
            this.loader.nativeElement.style.display = 'none'
            let dialogRef = this.dialog.open(UserMessageComponent, {
              data: {
                message: "Your order cannot be processed right now as some of the products in your order are currently unavailabe. Please try after sometime."
              },
              maxWidth: '450px'
            })
            console.log('Cannot generate order')
          }
  }

  cod() {
    console.log(this.country)
    this.loader.nativeElement.style.display = 'flex'
    this.order.type = 'COD'
    
    let dialogRef = this.dialog.open(DeliveryAddressComponent, {
      data: { order: this.orderId, country: this.country },
      maxHeight: '600px',
      maxWidth: '500px'
    })

    dialogRef.afterClosed()
      .subscribe(result => {
  
        this.updateOrder(result)
        console.log(result)
      }, error => {
        this.loader.nativeElement.style.display = 'none'
        console.log(error)
      })
  }

  async updateOrder(_orderInfo) {
    try{

      _orderInfo.currency = this.currency
      _orderInfo.amount = this.total
      this.orderId = await this.generateOrder()
      if(this.orderId){
        this.api.getOrderDetails(this.orderId)
        .subscribe((orderDetail: any) => {
          orderDetail.type = 'COD'
        orderDetail.status = 'Success'
        orderDetail.orderInfo = {
          ..._orderInfo
        }
        
        this.api.updateOrder(orderDetail)
        .subscribe((res) => {
          console.log(res)
          this.router.navigate(['myorderlistdetail', this.orderId])
        }, (err) => {
          console.log("Error updating Order", err)
        })
      })
    }else{
      this.loader.nativeElement.style.display = 'none'
            let dialogRef = this.dialog.open(UserMessageComponent, {
              data: {
                message: "Your order cannot be processed right now as some of the products in your order are currently unavailabe. Please try after sometime."
              },
              maxWidth: '450px'
            })
            console.log('Cannot generate order')
    }
  }catch(err){
    this.loader.nativeElement.style.display = 'none'
    console.log(err)
  }
  }

}

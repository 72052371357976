<div class="loader" #loader>
  <svg>
      <g>
        <path d="M 50,100 A 1,1 0 0 1 50,0"/>
      </g>
      <g>
        <path d="M 50,75 A 1,1 0 0 0 50,-25"/>
      </g>
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" style="stop-color:#5f1715;stop-opacity:1" />
          <stop offset="100%" style="stop-color:#f7f7e6;stop-opacity:1" />
        </linearGradient>
      </defs>
    </svg>
</div>
<div id="home_page_sec"  fxFlex="100" fxLayout="row wrap">
 
  <ngb-carousel fxFlex="100" fxLayout="row wrap" id="carouselExampleIndicators" class="carousel slide"  *ngIf="banners?.length > 0">
    <ng-template ngbSlide *ngFor="let banner of banners;let i = index">

    <img [src]="banner" alt="Random first slide" width="100%" >

    <p class="overtext">
      <span>
         <b>
          {{teasers[i].title}}
           </b><br>
        {{teasers[i].date | date: 'dd/MM/yyyy'}}<br>
        {{teasers[i].date | date: 'shortTime'}}
     
   
      </span>
     
    </p>
    <div class="carousel-caption">
    </div>
  </ng-template>

</ngb-carousel>

   

  <!--Broadcasted Items-->

  <section fxLayout="row wrap" fxFlex="100" *ngIf="broadcastedItems !== undefined && broadcastedItems?.length > 0" class="w-100 product_list_sec">


      <div class="container">
          <div class="inside_category" fxLayout="row wrap" fxFlex="100">
            <div fxLayout="row wrap" fxLayoutAlign="center center" fxFlex="100" id="treasury">
              <h3>
                  Broadcasted items
                  <div (mouseover)="tooltip()" (click)="tooltip()" class="info_icon">
                    <img src="assets/images/information.svg" draggable="false" />
                  </div>
                  <div *ngIf="toolTip" class="info_detail">
                      <p>
                        <span>Flash shopping section</span> : Lasts for just 24hrs! <br>
                        Limited items available!<br> Kindly refer to 'About Us' for more details
                      </p>
                    </div>
              </h3>
            </div>
              <div class="category_seperation" fxLayout="row wrap" fxFlex="100">
              
                <div class="gridview" fxLayout="row wrap" fxFlex.gt-xs="33" fxFlex.xs="100" *ngFor="let item of broadcastedItems; let i = index" (click)="gotoBroadcasted()">
                    <ng-container *ngIf="item.image && item.label">
                        <div fxFlex="100" id="gridlist">
                            <span class="image_section best_selling">
                                <img [src]="item.image" />
                              </span>   
                        </div>
                        <!-- <div fxFlex="100" class="deatial_sec_mob">
                          
                                {{item.label}}
                                <mat-divider></mat-divider>
                            
                        </div> -->
                      </ng-container>
                </div>
              </div>
              </div>
            </div>

  </section>






  <!--Broadcasted Items-->

  
  <!--Categories-->
  <section *ngIf="categories !== undefined && categories.length > 0" class="w-100 product_list_sec">
      <div class="container">
      <div class="inside_category" fxLayout="row wrap" fxFlex="100">
        <div fxLayout="row wrap" fxFlex="100" id="treasury" fxLayoutAlign="center center">
          <h3>
            Categories
          </h3>
        </div>
          <div class="category_seperation" fxLayout="row wrap" fxFlex="100">
          
            <div (click)="gotoProducts(category.id)" class="gridview" fxLayout="row wrap" fxFlex.gt-xs="33" fxFlex.xs="100" *ngFor="let category of categories">
              <div fxFlex="100" id="gridlist">
                <span  class="image_section">
                  <img [src]="getImageArray(category.category_image)" />
                </span>
              </div>
              <div fxFlex="100" fxLayout="row wrap" class="deatial_sec_mob">
                     <p fxFlex="100">
                       {{category.category_name}}
                     </p> 
                     <span class="divider" fxFlex="100"></span>
                     <p class="text">{{category.description}}</p>
              </div>
            </div>
          </div>
          </div>
        </div>
  </section>

  <section *ngIf="topSellingItems !== undefined && topSellingItems?.length > 0" class="w-100 product_list_sec">
    <div class="container">
      <div fxLayout="row wrap" fxFlex="100">
        <div fxFlex="100"  fxLayout="row wrap">
          <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center" id="treasury">
            <h3>
              Best Selling  
              <div (mouseover)="tooltip()" (click)="tooltip()" class="info_icon">
                  <img src="assets/images/information.svg" draggable="false" />
                </div>
                <div *ngIf="toolTip" class="info_detail">
                    <p>
                      <span>Made To Order Section</span> : Items are created on customer order !
                    </p>
                    <p>
                      <span>Delivery</span> : Within one month
                    </p>
                    <p>
                      <span>Advance Payment</span> : 30% of the item price<br> <span class="kindly_sec">Kindly refer to 'about us' for more details</span>
                    </p>
                  </div>
            </h3>
          </div>


          <div  class="inside_category" fxLayout="row wrap" fxFlex="100">
              <div class="category_seperation" fxLayout="row wrap" fxFlex="100">
              
                <div class="gridview best-sell" fxLayout="row wrap" fxFlex.gt-xs="33" fxFlex.xs="100" *ngFor="let top of topSellingItems; let i = index" (click)="gotoBestSelling(top.category)">
                 <ng-container *ngIf="top.images && top.label">
                   <div fxFlex="100" id="gridlist" >
                       <span class="image_section best_selling">
                           <img [src]="top.images" />
                         </span>
                         <span class="gradient"></span>
                   </div>
                   <div fxFlex="100" fxLayout="row wrap" class="deatial_sec_mob">
                       
                       <p fxFlex="100"> 
                         {{top.label}}
                       </p>                 
                   </div>
                 </ng-container>
                </div>
              </div>
              </div>
        </div>
      </div>
    </div>
  </section>

<!-- testimonials -->
<section fxLayout="row wrap" fxFlex="100" class="testimonial_sec">
    <div  fxLayout="row wrap" fxFlex="100" class="container"> 

      <div fxLayout="row wrap" fxFlex="100">
        <div fxLayout="row wrap" fxFlex="100">
          <h1 fxFlex="100">Customer Appraisals</h1>
        </div>
        <div fxFlex="100" id="conatiner">
          <p>{{testimonialsArrayShow?.content}}</p>
          <span class="review_name">{{testimonialsArrayShow?.heading}}</span>
        </div>

      </div>

      <span class="left" (click)="previous()">
          <img src="assets/images/left-arrow.svg" draggable="false">
      </span>
      <span class="right" (click)="next()">
          <img src="assets/images/right-arrow.svg" draggable="false">
      </span>

    </div>

  </section>
  <!-- testimonials -->




  
  <!-- treasury -->
  <section fxLayout="row wrap" fxLayoutAlign="center center" fxFlex="100" id="treasury">
    <div class="container"  fxLayout="row wrap" fxLayoutAlign="center center">
      <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row wrap">
        <h3>
          Treasury
          <div (mouseover)="tooltip()" (click)="tooltip()" class="info_icon">
            <img src="assets/images/information.svg" draggable="false" />
          </div>
          <div *ngIf="toolTip" class="info_detail">
              <p>
               Treasure of our valuable customer photos!!!
              </p>
            </div>
        </h3>

      </div>
      <div class="treasury_sec" *ngFor="let item of treasuryItems; let i = index"  (click)="gotoTreasury()"  fxFlex.gt-xs="20" fxFlex.xs="100">
        <span class="treasury_img">
          <img [src]="item.image" draggable="false" />
        </span>
      </div>
    </div>
  </section>
  <!-- treasury -->

  <ng-template #about>
    <div class="modal-header">
      <h4 class="modal-title pull-left">About Us</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>
        <pdf-viewer [src]="aboutUs" [render-text]="true" style="display: block;"></pdf-viewer>
      </p>
    </div>
  </ng-template>

  <ng-template #terms>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Terms and conditions</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>
        <pdf-viewer [src]="termsAndCond" [render-text]="true" style="display: block;">PDF viewer</pdf-viewer>
      </p>
    </div>
  </ng-template>

  <ng-template #privacy>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Privacy policy</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>
        <pdf-viewer [src]="privacyAndPolicy" [render-text]="true" style="display: block;"></pdf-viewer>
      </p>
    </div>
  </ng-template>
  <ng-template #returnPolicy>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Return Policy</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>
        <pdf-viewer [src]="returnPolicyDoc" [render-text]="true" style="display: block;"></pdf-viewer>
      </p>
    </div>
  </ng-template>

  <ng-template #contact>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Our Address</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>
        Tazzels3<br>
        11/769/15 Meledath Stephan Memorial, <br>
        Building Mother Junction, Ollur, Thrissur<br>
        680306 <br>
        Ph No. +91.9886046697<br>
        Email : tazzles3@gmail.com
      </p>
    </div>
  </ng-template>

</div>

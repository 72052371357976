import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})

export class DataService {

	private _currency = new Subject<string>();
  public currency = this._currency.asObservable()
  private _dosearch = new Subject<string>();
	public dosearch = this._dosearch.asObservable()

  constructor(
    private http: HttpClient
  ) { }

  selectCurrency(cur){
  	this._currency.next(cur)
  }

  updateSearch(search:string){
    console.log("searchhh", search)
      this._dosearch.next(search)
  }

  getCountryCode(name){
    return new Promise((resolve, reject)=>{
      let countryList = this.http.get('assets/data/countrylist.json')
      countryList.subscribe((list:any)=>{
       let country = list.find((data)=>{
            return data.name.toLowerCase() === name.toLowerCase()
        })
        if(country)
          resolve(country)
        else
          reject('Not found')
      })
    })
  }

  getAllCountry(){
    return new Promise((resolve, reject)=>{
      let countryList = this.http.get('assets/data/countrylist.json')
      countryList.subscribe((list:any)=>{
       if(list)
          resolve(list)
        else
          reject('Not found')
      })
    })
  }


}

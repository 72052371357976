import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyorderdetailComponent } from './myorderdetail.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {NgxPaginationModule} from 'ngx-pagination';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxInputStarRatingModule } from 'ngx-input-star-rating';
import { MyorderdetailRoutingModule } from './myorderdetail-routing.module';
import { FormsModule } from '@angular/forms';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap'
@NgModule({
  declarations: [MyorderdetailComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatStepperModule,
    MatTabsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule,
    NgxPaginationModule,
    NgxInputStarRatingModule,
    FormsModule,
    ReactiveFormsModule,
    MyorderdetailRoutingModule,
    NgbAlertModule
  ]
})
export class MyorderdetailModule { }

<div fxLayout="row wrap" fxFlex="100" id="treasury_outer_sec">
  <h3 fxFlex="100">Treasury</h3>
  <div fxFlex="100" fxLayout="row wrap">
    <div fxFlex.gt-xs="33" fxFlex.xs="100" class="product_outer" fxLayout="row wrap" fxLayoutAlign="center center" *ngFor = "let product of products">
      <div (click)="openDialog(product)" fxFlex="100" class="image_section">
        <span class="overlay">
          {{product.text}}
        </span>
        <img [src]="product.image" draggable="false"/>
      </div>
    </div>
  </div>
</div> 

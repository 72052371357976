import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../services/api.service';
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { NgbActiveModal, NgbModal, NgbAlert } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    userError = false
  loginForm = new FormGroup({
      email: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
  })
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private api: ApiService,
        public activeModal: NgbActiveModal
        ) {}

    ngOnInit() {}

    onLogin() {
       this.userError=false
        console.log(this.loginForm.value)
        this.api.userAuth(this.loginForm.value)
            .subscribe((res:any) =>{
                console.log("Login response", res)
                let _res = JSON.parse(JSON.stringify(res))
                if(_res){
                    console.log(_res)
                    localStorage.setItem('user', _res.result.profile.id)
                    localStorage.setItem('access_token', _res.result.id)
                    // if(_res.result.profile.emailVerified){
                        localStorage.setItem('isLoggedin', 'true');
                        this.activeModal.close('success')   
                    // }else{
                    //     this.activeModal.close('Phone_not_verified')  
                    //     this.router.navigate(['/otp',_res.result.profile.id])
                    // }
                } 
            }, (err: any)=>{
                    this.userError = true
                })
    }
    forgotpassword(){
        this.activeModal.dismiss();
        this.router.navigateByUrl('/forgotpassword');
    }

    toSignUp(){
        this.activeModal.dismiss()
        this.router.navigate(['signup'])
    }
   
}

import { NgModule} from '@angular/core';
import { Routes, RouterModule } from '@angular/router'

import { OrderStatusComponent } from './order-status.component';

const routes: Routes = [
	{path: '', component: OrderStatusComponent}
]

@NgModule({
	imports: [
		RouterModule.forChild(routes)
	]
})

export class OrderStatusRoutingModule {}

import { Component, OnInit, ViewChild } from '@angular/core';
// import { Observable } from 'rxjs/Observable';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { DataService } from '../services/data.service'
import { environment } from 'src/environments/environment';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap'
@Component({
  selector: 'app-myorderdetail',
  templateUrl: './myorderdetail.component.html',
  styleUrls: ['./myorderdetail.component.scss']
})
export class MyorderdetailComponent implements OnInit {
  @ViewChild('form') form
  // value: Observable<number>;
  orderId;
  order;
  products = [];
  address;
  review;
  currency;
  user;
  accessCode = environment.ccav_accessCode;
  encRequest;
  successMessage
  messageType
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private data: DataService
    ) { }

  ngOnInit() {
    this.currency = localStorage.getItem('currency')
    if(!this.currency)
      this.currency = 'INR'
    this.orderId = this.route.snapshot.params.orderId
    this.api.getOrderDetails(this.orderId).subscribe((res:any)=>{
      this.order = res
      console.log(this.order)
      if(this.order.order_status == 'ready_to_dispatch')
        this.order.order_status = 'Ready to dispatch'
      this.address = res.orderInfo.delivery_name + ', ' + res.orderInfo.delivery_address + ', ' + res.orderInfo.delivery_city + ',' + res.orderInfo.delivery_state + ', ' +  res.orderInfo.delivery_zip + ', ' + res.orderInfo.delivery_country
      res.products.forEach((prod: any)=>{
        this.api.getProductDetails(prod.product_id, this.currency)
          .subscribe((prodDetails:any)=>{
            console.log(prodDetails)
            this.products.push(prodDetails.success[0]);
          }, (err)=>{
            console.log("prodDetail errr",err)
          })
      })
      console.log('productsss', this.products)
    })
    // this.form = this.formBuilder.group({
    //   rating: [0]
    // });
    let userId = localStorage.getItem('user')
    this.api.getMemberDetails(userId)
      .subscribe(user => {
        this.user = user        
      })
 
    // this.value = this.form.controls.rating.valueChanges;
  }

  saveReview(){
    console.log('herer')
    if(this.review !== ''){
      
      let review = {
        title: this.user.username,
        description: this.review,
        author: this.user.username,
        isapproved: false,
        country_name: this.user.countrycode
      } 
      console.log("review", review)
      this.api.addReview(review)
      .subscribe((res)=>{
        this.messageType = 'success'
        this.successMessage = 'Review saved!'
        this.review = ''
        setTimeout(()=>{
          this.successMessage = null
        },6000)
        console.log(res)
      })
    }
  }

  pay(){
    let data:string = `order_id=${this.order.id}&currency=${this.currency}&amount=${this.order.balance_amount}&language=EN`
    data = data.toString()
    this.api.getEncRequest(data)
      .subscribe((res:any) =>{
        console.log(res)
        this.encRequest = res.success;
      console.log(this.encRequest, this.accessCode)
      console.log("Start payment process")
      if(this.encRequest && this.accessCode){
        debugger;
        setTimeout(() => {
          this.form.nativeElement.submit()
        }, 2000)
      }
      }) 
  
  }

}

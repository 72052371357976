<div fxLayout="row wrap" fxFlex="100" class="login-page">
    <div fxLayout="row wrap" fxFlex="100" class="content">
      <!-- <h1 class="app-name">Sb Admin Material</h1> -->
      <button type="button" class="close pull-right" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
      <form fxLayout="row wrap" fxFlex="100" [formGroup]="loginForm" class="login-form" fxFlex>
        <div fxFlex="100" class="text-center">
          <h2 class="app-name">Login</h2>
        </div>
        <div *ngIf="userError" fxFlex="100" fxlayout="row wrap">
            <ngb-alert [dismissible]="false" type="danger" (close)="userError = false" >
              Username or password wrong!
            </ngb-alert>
        </div>
        <div fxFlex="100" fxlayout="row wrap">
          <div fxFlex="100" fxlayout="row wrap">
            <mat-form-field fxFlex="100" class="w-100">
              <input matInput placeholder="Email" formControlName="email">
            </mat-form-field>
          </div>
        </div>
        <div fxFlex="100" fxLayout="row wrap">
          <div fxLayout="row wrap" fxFlex="100">
            <mat-form-field fxFlex="100" class="w-100">
              <input matInput type="password" placeholder="Password" formControlName="password">
            </mat-form-field>
          </div>
        
        </div>
        <div fxFlex="100" fxLayout="row wrap" style="margin: 20px 0 30px 0">
          <div fxFlex="50" fxLayout="row wrap" fxLayoutAlign="start center">
            <!-- <mat-checkbox color="primary">Remember Me</mat-checkbox> -->
          </div>
          <div fxFlex="50" fxLayout="row wrap" fxLayoutAlign="end end">
            <a (click)="forgotpassword()" href="javascript:void(0)">Forgot Password?</a>
          </div>
        </div>
        <div fxFlex fxLayout="row wrap" fxLayout.lt-md="column">
          <div fxFlexFill>
            <button mat-raised-button ngbAutofocus [disabled]="!loginForm.valid" class="w-100 btn" (click)="onLogin()">Login</button>
          </div>
        </div>
        <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center" fxLayout.lt-md="column">
          <div fxFlex="100">
              New user? <a style="cursor: pointer;font-size: 18px;" (click)="toSignUp()"><b>Sign up</b></a>
          </div>
        </div>
      </form>
    </div>
  </div>
  
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderStatusRoutingModule } from './orderstatus-routing.module'
import { OrderStatusComponent } from './order-status.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {NgxPaginationModule} from 'ngx-pagination';

@NgModule({
  declarations: [OrderStatusComponent],
  imports: [
    CommonModule,
    OrderStatusRoutingModule,
    FlexLayoutModule,
    MatStepperModule,
    MatTabsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule,
    NgxPaginationModule
  ]
})
export class OrderStatusModule { }

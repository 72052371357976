import { NgModule} from '@angular/core';
import { Routes, RouterModule } from '@angular/router'

import { CartComponent } from './cart.component'

const routes: Routes = [
	{path: '', component: CartComponent}
]

@NgModule({
	imports: [
		RouterModule.forChild(routes)
	]
})

export class CartRoutingModule {}

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WindowService } from '../services/window.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { ApiService } from '../services/api.service'
import * as firebase from 'firebase/app';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap'
@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit, AfterViewInit {
  windowRef
  phoneNumber
  verificationCode
  user
  userId
  userDetails
  countryCode
  num
  oldNum
  editNum = false;
  error;
  constructor(
    private win: WindowService,
    private route: ActivatedRoute,
    private fireAuth: AngularFireAuth,
    private router: Router,
    private api: ApiService
  ) { }

  ngOnInit() {
    this.userId = this.route.snapshot.params.id
    this.windowRef = this.win.windowRef
    console.log("phoneeeeeeeee", this.userId)
   
  }
  ngAfterViewInit(){
    // this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container',{
    //   'size': 'invisible',
    //   'callback': function(response) {
    //     // reCAPTCHA solved, allow signInWithPhoneNumber.
    //     // onSignInSubmit();
        
    //     this.sendLoginCode(response)
    //   }
    // })
    // this.windowRef.recaptchaVerifier.render() 
    this.api.getMemberDetails(this.userId)
    .subscribe((details:any)=>{
      this.userDetails = details
      this.phoneNumber = details.phoneno
      this.countryCode = details.countrycode
      this.num = this.countryCode + this.phoneNumber;
      this.oldNum = this.phoneNumber
      this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
        'size': 'invisible',
        'callback': function(response) {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          
        }});
        this.windowRef.recaptchaVerifier.render() 
        this.sendLoginCode() 
      })
  }

  sendLoginCode() {
    const appVerifier =  this.windowRef.recaptchaVerifier;
    if(this.oldNum !== this.phoneNumber)
      this.num = this.countryCode + this.phoneNumber
    
  
    this.fireAuth.auth.signInWithPhoneNumber(this.num, appVerifier)
            .then(result => {
                console.log("signinmnnnnnnnn", result)
                this.windowRef.confirmationResult = result;
                
            })
            .catch( error => console.log(error) );

  }
  verifyLoginCode() {
    console.log("Verifiaction code........",this.verificationCode)
    this.windowRef.confirmationResult
                  .confirm(this.verificationCode)
                  .then( result => {
                    
                    this.user = result.user;
                    this.userDetails.emailVerified = true
                    this.userDetails.phoneno = this.phoneNumber
                    this.api.updateMember(this.userDetails)
                      .subscribe((res)=>{
                          this.router.navigate(['/home'])
                      }, (err)=>{
                        console.log(err)
                        this.error = 'Phone number already used!'
                      })

    })
    .catch( error => console.log(error, "Incorrect code entered?"));
  }

}

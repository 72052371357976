<div fxFlex="100" fxLayout="row wrap" id="myorder_detail">
  <h2 fxFlex="100">Order detail page</h2>
  <div fxFlex.gt-xs="50" fxFlex.xs="100" class="left_view" fxLayout="row wrap">
    <h6 fxFlex="100">Order number : {{order?.id}}</h6>
    <h5 fxFlex="100" class="status">Status: {{order?.status}}</h5>

    <div fxLayout="row wrap" fxFlex="100" class="payment_sec">
      <p fxFlex="100">Total: {{order?.total}} {{order?.currency}}</p>
      <p fxFlex="100" *ngIf="order?.type == 'made_to_order' && (order?.status === 'Order_placed'|| order?.status === 'Ready to dispatch') ">Balance to pay:
        {{order?.balance_amount}} {{order?.currency}}</p>
        <p fxFlex="100" *ngIf="order?.type == 'made_to_order' && (order?.status === 'Payment_completed'|| order?.status === 'Dispatched'|| order?.status === 'Delivered') ">Total paid:
          {{order?.amount_paid}} {{order?.currency}}</p>
      <ul class="list_view">
        <li *ngFor="let product of products">
          <a *ngIf="product?.name" [routerLink]="'/detailview/'+product.id+'/'+currency">{{product?.name}}</a> 
          <a *ngIf="!product?.name" [routerLink]="'/detailview/'+product.id+'/'+currency">{{product?.product_id}}</a> 

        </li>
      </ul>
    </div>
    <div fxLayout="row wrap" fxFlex="100" class="shipping_sec">
      <h3 fxFlex="100">Shipping address</h3>
      <p fxFlex="100">{{address}}</p> <br/>
      <p fxFlex="100">Tel: {{order?.orderInfo.delivery_tel}}</p>
    </div>
    <div fxLayout="row wrap" fxFlex="100" class="review_sec">
      <h3 fxFlex="100">Review</h3>
      <div fxFlex="100" fxLayout="row wrap">
        <mat-form-field fxFlex="100" appearance="outline">
          <textarea matInput [(ngModel)]="review" placeholder="Review"></textarea>
        </mat-form-field>
        <!-- <div fxFlex="100">
          <ngx-input-star-rating formControlName="rating"></ngx-input-star-rating>
        </div> -->
        <p  fxFlex="100" *ngIf="successMessage" class="alert" >
            <ngb-alert [dismissible]="true" [type]="messageType" (close)="successMessage = null">
              {{successMessage}}
            </ngb-alert>
          </p>
        <div fxFlex="100" class="btn_sec">
          <button class="save_btn" mat-button (click)="saveReview()">Save</button>
        </div>
      </div>
    </div>
    <!-- <div *ngIf="order?.type == 'made_to_order' && order?.order_status == 'Ready to dispatch'" fxLayout="row wrap" fxFlex="100"
      class="review_sec">
      <div fxFlex="100" class="btn_sec">
        <button class="save_btn" mat-button (click)="pay()">Pay</button>
      </div>
    </div> -->
  </div>
  <div fxHide fxFlex.gt-xs="50" fxFlex.xs="100" class="right_view">
    <div fxFlex="100" class="image_sec">
      <img src="assets/images/product1.png" draggable="false" />
    </div>
  </div>

  <form #form ngNoForm id="nonseamless" method="post" name="redirect"
    action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction">
    <input type="hidden" id="encRequest" name="encRequest" value="{{encRequest}}">
    <input type="hidden" name="access_code" id="access_code" value="{{accessCode}}">
  </form>
</div>
import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-myorder',
  templateUrl: './myorder.component.html',
  styleUrls: ['./myorder.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MyorderComponent implements OnInit {

  isLinear;
firstFormGroup;
secondFormGroup;
hide = true;
orderStatus: any = [] ;
cities: any = [];
states: any = [];
  constructor() { }

  ngOnInit() {

    this.orderStatus = [
      {
        image : 'assets/images/product1.png',
        code : '123Tazzels',
        item : 'Lorem ipsum',
        size : 40,
        quantity: 1,
        price: '$1500'
      },
      {
        image : 'assets/images/product1.png',
        code : '123Tazzels',
        item : 'Lorem ipsum',
        size : 40,
        quantity: 1,
        price: '$1500'
      },
      {
        image : 'assets/images/product1.png',
        code : '123Tazzels',
        item : 'Lorem ipsum',
        size : 40,
        quantity: 1,
        price: '$1500'
      },
      {
        image : 'assets/images/product1.png',
        code : '123Tazzels',
        item : 'Lorem ipsum',
        size : 40,
        quantity: 1,
        price: '$1500'
      },
    ];
  }

}

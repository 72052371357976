<div fxLayout="row wrap" id="order_summary" fxFlex="100">
  <div fxFlex.gt-xs="70" fxFlex.xs="100" fxLayout="row wrap">

    <!-- stepper -->

    <mat-horizontal-stepper fxFlex="100" [linear]="isLinear" #stepper>
      <mat-step [stepControl]="firstFormGroup">
        <form>
          <ng-template matStepLabel>Summary</ng-template>
          <div fxLayout="row wrap" fxFlex="100">
          
            
            
                <div class="summary_sec" fxFlex="100" *ngFor="let order of orderStatus" fxLayout="row wrap">
                  <div class="summary_image_sec" fxFlex.gt-xs="20" fxFlex.xs="100">
                    <span class="image_view">
                      <img [src]="order.image" draggable="false" />
                    </span>
                  </div>
                  <div fxFlex.gt-xs="30" fxFlex.xs="100" class="item_details">
                    <h3>{{order.code}}</h3>
                    <h4>{{order.item}}</h4>
                    <p class="delete_sec">
                
                    </p>
                  </div>
                  <div class="size_deatils" fxLayout="row wrap" fxFlex.gt-xs="30" fxFlex.xs="100" fxLayout="row wrap">

               

                   <div fxLayout="row wrap" fxLayoutAlign="center center" fxFlex="50">Quantity</div>

                    <mat-form-field fxFlex="50" appearance="outline">
                  
                      <mat-select [disabled]="order.quantity" [value]="order.quantity">
                        <mat-option [value]="order.quantity">
                          {{order.quantity}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="price_details" fxFlex.gt-xs="20" fxFlex.xs="100">
                    <p>Price : {{order.price}} {{currency}}</p>
                  </div>
                </div>
            

          </div>
        </form>
      </mat-step>
   
      
    </mat-horizontal-stepper>

    <!-- stepper -->



  </div>
  <div fxFlex.gt-xs="30" fxFlex.xs="100" fxLayout="row wrap">
    <div *ngIf="products.length > 0 && order" fxFlex="100" fxLayout="row wrap"  class="ordersummary">
      <h2 fxFlex="100">Order Summary <span>{{products.length}} Items</span></h2>
      <h4 fxFlex="100">Order value <span>{{orderValue}} {{currency}}</span></h4>
      <h4 fxFlex="100">Shipping <span>{{shippingCharge}} {{currency}}</span></h4>
      <h5 fxFlex="100">Grand total <span>{{total}} {{currency}}</span></h5>
      <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
        <button *ngIf="country == 'UAE'||country == 'AED'" class="order_btn" mat-button (click)="cod()" >Cash on Delivery</button>
        <button class="order_btn" mat-button (click)="pay()">Pay Now</button>
      </div>

    </div>
    <form #form ngNoForm
  id="nonseamless" 
  method="post" 
  name="redirect" 
  action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction">
    <input type="hidden" id="encRequest" name="encRequest" value="{{encRequest}}">
    <input type="hidden" name="access_code" id="access_code" value="{{accessCode}}">
</form>

  </div>

  <div class="loader" #loader>
    <svg>
        <g>
          <path d="M 50,100 A 1,1 0 0 1 50,0"/>
        </g>
        <g>
          <path d="M 50,75 A 1,1 0 0 0 50,-25"/>
        </g>
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" style="stop-color:#5f1715;stop-opacity:1" />
            <stop offset="100%" style="stop-color:#f7f7e6;stop-opacity:1" />
          </linearGradient>
        </defs>
      </svg>
  </div>
</div>


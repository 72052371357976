import { Component, OnInit, ViewChild } from '@angular/core';
import { LoginComponent } from '../login/login.component';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { ApiService } from '../services/api.service'
import { DataService } from '../services/data.service'
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap'
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  error = null;
  countries:any = [];
  dialCode = ''
  @ViewChild('loader') loader
  constructor(
    private modelService: NgbModal,
    private router: Router,
    private api: ApiService,
    private data: DataService
    ) { }
  signup = new FormGroup({
    username: new FormControl('', Validators.required),
    countryname: new FormControl('', Validators.required),
    phoneno: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    confirmPwd: new FormControl('', Validators.required),
    agree: new FormControl(false, Validators.required)
  })

  ngOnInit() {
    this.data.getAllCountry()
      .then((res:any)=>{
      this.countries = res
      this.loader.nativeElement.style.display = 'none'
    })
  }
  Signup(){
    console.log("Form dataa",this.signup.value, this.signup.valid)
    let pass = this.signup.value.password
    let confirmPass = this.signup.value.confirmPwd

    if(this.signup.valid){
      if(pass == confirmPass && this.signup.value.agree){
        if(this.checkPasswords(pass)){
        let countryCodes = this.countries.find((country)=> country.name == this.signup.value.countryname)
        this.loader.nativeElement.style.display = 'flex'
        console.log("country codeeee---", countryCodes)

        let userData = {
          username  : this.signup.value.username,
          countrycode : countryCodes.dial_code,
          phoneno : this.signup.value.phoneno.toString(),
          email :  this.signup.value.email,
          password : this.signup.value.password,
          user_type : 'customer',
          emailVerified: true
        }
        
        this.api.user_signup(userData)
        .subscribe((response:any)=>{     
          console.log("ressss",response)
          if(response){
            console.log('User added successfully')
            setTimeout(()=>{
              this.router.navigate(['/home'])
              
              // this.router.navigate(['/otp',response.id]);
            }, 2000)
          }
        }, (err)=>{
          this.loader.nativeElement.style.display = 'none'
          this.error = 'Email/Phone number already exists. Please login to continue'
          setTimeout(()=>{
            this.error = null;
          }, 6000)
        })
      }else{
        this.error = "Password too weak! Password should be atleast 8 characters long, include a small letter, one capital letter, a digit and a symbol."
        setTimeout(()=>{
          this.error = null;
        }, 6000)
      }
      }else{
        if(pass != confirmPass){
          this.error = "Password and confirm password doesn't match!"
          setTimeout(()=>{
            this.error = null;
          }, 6000)
        }
        else{
          this.error = "Please accept the terms and conditions"
          setTimeout(()=>{
            this.error = null;
          }, 6000)
        }
      }
    }else{
      this.error = "Please fill all the fields!"
      setTimeout(()=>{
        this.error = null;
      }, 6000)
    }
  }

  countrySelected(country){
    console.log(country)
    let countryCodes = this.countries.find(codes => country === codes.name)
      console.log(countryCodes)
      this.dialCode = countryCodes.dial_code
   
  }
  checkPasswords(pass) { // here we have the 'passwords' group
  
  let strongPass = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
  if(pass.match(strongPass))
  return true
  else
  return false 
}
  onLogin(){
    const modelRef = this.modelService.open(LoginComponent)
    modelRef.result.then((res)=>{
      if(res === 'success')
        this.router.navigate(['/home'])
    }, (reason)=>{
      console.log("loggin Failed: ", reason)
    })
  }

}

import { NgModule} from '@angular/core';
import { Routes, RouterModule } from '@angular/router'

import { SignupComponent } from './signup.component';

const routes: Routes = [
	{path: '', component: SignupComponent}
]

@NgModule({
	imports: [
		RouterModule.forChild(routes)
	]
})

export class SignupRoutingModule {}

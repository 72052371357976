import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient
  ) { }

  productList() {
    let httpOptions = {
      headers: new HttpHeaders({'Access-Control-Allow-Methods': "GET, POST, DELETE, PUT"})
    }
    return this.http.get(environment.apiUrl + 'products/get_productlist_front', httpOptions);
  }

  getAboutUs(){
    return this.http.get(environment.apiUrl + 'about_us')
  }
  
  getTerms(){
    return this.http.get(environment.apiUrl + 'terms_conditions')
  }

  getPrivacy(){
    return this.http.get(environment.apiUrl + 'privacy_policies')
  }

  getCurrencies(){
    return this.http.get(environment.apiUrl + 'countrycurrencies')
  }

  userAuth(cred){
    return this.http.post(environment.apiUrl + 'members/login_user', cred)
  }

  getProductCategory(filter){
    console.log(filter)
    return this.http.get(environment.apiUrl + 'products/get_all_products_category?'+filter)
  }

  getProductDetails(product, currency){
    return this.http.get(environment.apiUrl + 'products/get_product_detail?product_id=' + product + '&' + 'currency=' + currency)
  }

  searchProduct(currency, keyword){
    return this.http.get(environment.apiUrl + 'products/get_product_list_search?currency=' + currency + '&search=' + encodeURI(keyword))
  }

  getProfile(id){
    return this.http.get(environment.apiUrl + 'members/' + id)
  }

  updateProfile(data){
    return this.http.post(environment.apiUrl + 'members/update_user_profile', data)
  }

  updateUserPhoneNumber(userId, phoneNo){
    let headerOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json'
      })
    }
    let formData = new FormData()
    formData.append('phone_no', phoneNo)
    formData.append('user_id', userId)
    return this.http.post(environment.apiUrl + 'members/update_user_phone_no', formData, headerOptions)
  }

  logout(token){
    return this.http.get(environment.apiUrl + 'members/logout_user?access_token=' + token)
  }

  getEncRequest(data){
    console.log(data)
    return this.http.post(environment.apiUrl + 'products/ccavRequestHandler', { 'data': data})
  }

  addToCart(data: any){
    return this.http.get( environment.apiUrl + 'cart_list/save_cart_details?product_id=' + data.product_id + '&user_id=' + data.user_id + '&quantity=' + data.quantity )

  }

  getCart(user_id, cur){
    return this.http.get(environment.apiUrl + 'cart_list/get_all_cart_list_of_user?user_id=' + user_id + '&currency=' + cur)
  }
  removeCart(userId){
    return this.http.get(environment.apiUrl + 'cart_list/remove_cart?user_id'+userId)
  }


  filterProductsInCategory(filter){
    return this.http.get(environment.apiUrl + 'products/get_all_list_search_category?'+ filter)
  }

  getCategories(){
    return this.http.get(environment.apiUrl + 'categories/get_category_subcategory');
  }

  getTreauries(){
    return this.http.get(environment.apiUrl + 'treasuries');
  }

  getBroadcasted(cur){
    return this.http.get(environment.apiUrl + 'products/get_broadcasted_items?currency='+cur);
  }

  generateOrder(order){
    return this.http.post(environment.apiUrl +'orders/generate_order', order)
  }

  getOrderDetails(id){
    return this.http.get(environment.apiUrl + 'orders/'+id)
  }

  updateOrder(order){
    return this.http.post(environment.apiUrl + 'orders/replaceOrCreate', order)
  }

  getProduct(id){
    return this.http.get(environment.apiUrl + 'products/'+id)
  }

  
  get_shipping_charge(order_details){
    return this.http.post(environment.apiUrl + 'extra_charges/get_shipping_charge', order_details)
  }

  get_shipping_countries(){
    return this.http.get(environment.apiUrl + 'extra_charges')
  }

  user_signup(userData){
    return this.http.post(environment.apiUrl + 'members', userData)
  }

  getMemberDetails(id){
    return this.http.get(environment.apiUrl + 'members/'+id)
  }

  updateMember(data){
    let filter = JSON.stringify({
      id: data.id
    })

    return this.http.post(environment.apiUrl + 'members/upsertWithWhere?where='+ encodeURI(filter), data)
  }

  getUserOrders(userId){
    return this.http.get(environment.apiUrl + 'orders/get_all_order_list_of_user?userId='+userId)
  }

  resetUserPassword(email){
    let headerOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json'
      })
    }
    return this.http.post(environment.apiUrl + 'members/reset_password_user', 'email='+email, headerOptions)
  }

  setUserPassword(token, newPassword){
    let headerOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json'
      })
    }
      return this.http.post(environment.apiUrl + 'members/reset-password?access_token='+token, 'newPassword='+newPassword, headerOptions)
  }

  addReview(review){
    return this.http.post(environment.apiUrl + 'reviews', review)
  }
  
}



import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router'
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LoginComponent } from '../login/login.component';
import { ApiService } from '../services/api.service';
import { DataService } from '../services/data.service'
import { environment } from '../../environments/environment'


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isClicked = true;
  isLoggedIn;
  currency = 'INR';
  currencies;

  constructor(
      private modelService: NgbModal,
      private ref: ChangeDetectorRef,
      private api: ApiService,
      private router: Router,
      private data: DataService
    ) { }
  ProfileSec:boolean = false
  profileImg = 'assets/images/avatars/avatar1.png'
  ngOnInit() {
    this.isLoggedIn = localStorage.getItem('isLoggedin');
    let cur = localStorage.getItem('currency')
    this.currency = cur
    if(this.isLoggedIn == 'true'){
      let id = localStorage.getItem('user')
  	this.api.getProfile(id)
  		.subscribe((res:any)=>{
        if(res){
          if(res.profile_image)
          this.profileImg = environment.s3_url+'thumb_profiles/'+ res.profile_image; 
        }
  		})
    }
    this.api.getCurrencies()
      .subscribe((cur)=>{
        this.currencies = cur;
 
      })
    this.ref.detectChanges()

    window.onscroll = function() {myFunction()};
    
    var navbar = document.getElementById("header_sec");
    var sticky = navbar.offsetTop;
    
    function myFunction() {
      if (window.pageYOffset >= sticky) {
        navbar.classList.add("sticky")
      } else {
        navbar.classList.remove("sticky");
      }
    }

   
  }

  ngAfterViewInit(){
    if (screen.width < 600){
      this.isClicked = false;
    }
    console.log("+++++",this.isClicked)
  }


  menuopen(){
   
    this.isClicked = !this.isClicked;
    console.log(this.isClicked)
  }

  profile(){
    this.ProfileSec = !this.ProfileSec;
  }

  onLogin(){
    const modelRef = this.modelService.open(LoginComponent)
    modelRef.result.then((res)=>{
      if(res === 'success')
         this.ngOnInit();
    }, (reason)=>{
      this.ngOnInit();
      console.log("loggin Failed: ", reason)
    })
  }

    search(event: any, searchBox:any){
      console.log("seracheeee");
      let searchTerm = searchBox.value
      this.router.navigate(['listview', 'search='+searchTerm])
      console.log(searchTerm)
      this.data.updateSearch(searchTerm)
  }

  gotoCart(){
    let isLoggedin = localStorage.getItem('isLoggedin')
    console.log("loggedin?",isLoggedin)
    if(isLoggedin == 'false' || !isLoggedin){
      const modelRef = this.modelService.open(LoginComponent)
    modelRef.result.then((res)=>{
      if(res === 'success')
         this.ngOnInit();
         this.router.navigate(['/cart'])
    }, (reason)=>{
      console.log("loggin Failed: ", reason)
    })
    }else{
      this.router.navigate(['cart']).then(()=>{
        console.log('Navigated to cart')
      },(reason)=>{
        console.log(reason)
      })

    }
  }

  gotoMyorders(){
    let userId = localStorage.getItem('user');
    this.router.navigate(['/myorderlist', userId])
  }

   logout(){
    let token = localStorage.getItem('access_token')
    if(token){
      localStorage.setItem('isLoggedin', 'false')
      localStorage.removeItem('user')
      localStorage.removeItem('access_token')
    this.api.logout(token)
        .subscribe(res =>{     
    this.ngOnInit()
      this.router.navigate(['/home']);
     })
    }
  }

  onCurrency(cur){
    localStorage.setItem('currency', cur)
    this.data.selectCurrency(cur)
    console.log(cur)
  }

 

}

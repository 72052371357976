import { Component, OnInit } from '@angular/core';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap'
import { ApiService } from '../services/api.service'

const validEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  email;
  sent = false;
  alert = null;
  constructor(
    private api: ApiService
  ) { }

  ngOnInit() {
  }

  sendPassword(){
    let valid = validEmail.test(this.email.toLowerCase())
    if(valid){
      this.api.resetUserPassword(this.email)
        .subscribe((res)=>{
          this.sent = true
          if(res){
            console.log(res)
            this.alert = {
              type: 'success',
              message: 'Email send to ' + this.email
            }
          }
          console.log('Email send')
        }, (err)=>{
          if(err)
          this.alert = {
            type: 'danger',
            message: 'Please check the email you have entered. If not a user please signup'
          }
        })
    }else{
      this.alert = {
        type: 'danger',
        message: 'Please a valid email address.'
      }
    }
  }

}

import { Component, OnInit, TemplateRef } from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from '../../environments/environment'
import { ApiService } from '../services/api.service'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
modalRef;
aboutUs: any;
termsAndCond: any;
privacyAndPolicy: any;
returnPolicyDoc: any;
  constructor(
  	private modal:NgbModal,
  	private api:ApiService
  	) { }

  ngOnInit() {

  	this.api.getAboutUs()
      .subscribe((res:any)=>{
        console.log(res);
        this.aboutUs = environment.s3_url + 'about/' + res[0].image
      })
    this.api.getTerms()
      .subscribe((res:any)=>{
        console.log(res);
        this.termsAndCond = environment.s3_url + 'terms/' + res[0].image
      })
    this.api.getPrivacy()
      .subscribe((res:any)=>{
        console.log(res);
        this.privacyAndPolicy = environment.s3_url + 'privacy/' + res[0].image
      })
    this.returnPolicyDoc = environment.return.toString();
    console.log(typeof this.termsAndCond);
    console.log(this.aboutUs);
  }

  onModal(ref : TemplateRef<any>) {
    this.modalRef = this.modal.open(ref, { size: 'lg' });
  }

}

<div class="popup">

  <div fxLayout="row wrap" fxFlex="100" id="address_pop">
    <h3 fxFlex="100" class="head">Enter delivery address</h3>
    <ngb-alert fxFlex="100" type="danger" [dismissible]="false" *ngIf="error">
      All fields are required!
    </ngb-alert>
    <form fxLayout="row wrap" fxFlex="100" [formGroup]="address">
      <mat-form-field fxFlex="100" appearance="outline">
        <input matInput formControlName="delivery_name" placeholder="Full Name">
      </mat-form-field>
      <mat-form-field fxFlex="100" appearance="outline">
        <input matInput formControlName="delivery_address" placeholder="Address">
      </mat-form-field>
      <mat-form-field fxFlex="100" appearance="outline">
        <input matInput formControlName="delivery_city" placeholder="City">
      </mat-form-field>
      <mat-form-field fxFlex="100" appearance="outline">
        <input matInput formControlName="delivery_state" placeholder="Emirate/State">
      </mat-form-field>
      <mat-form-field fxFlex="100" appearance="outline" >
        <p>+971</p> <input matInput formControlName="delivery_tel" placeholder="Phone number">
      </mat-form-field>
      <mat-form-field fxFlex="100" appearance="outline">
        <input matInput formControlName="delivery_country" readonly>
      </mat-form-field>
    </form>

    <div class="btn_sec">
      <button mat-button  (click)="continue()">Continue</button>
    </div>
  </div>

  <!-- <div class="loader" #loader>
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div> -->

</div>
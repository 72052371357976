import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { HomePageModule } from './home-page/home-page.module';
import { LoginModule } from './login/login.module';
import { AppComponent } from './app.component';
import { HeaderModule } from './header/header.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ListviewModule } from './listview/listview.module';
import { ListpopupComponent } from './listview/listpopup/listpopup.component';
import { DetailviewComponent } from './detailview/detailview.component';
import { PaymentFailureComponent } from './payment-failure/payment-failure.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import {MatButtonModule} from '@angular/material/button';
import { ProfilePageComponent } from './profile-page/profile-page.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material'
import {MatInputModule} from '@angular/material';
import {MatIconModule} from '@angular/material/icon';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap'
import {CartModule} from './cart/cart.module';
import {TreasuryModule} from './treasury/treasury.module';
import {OrderDetailModule} from './order-detail/order-detail.module';
import {SlideshowModule} from 'ng-simple-slideshow';
import {OrderSummaryModule} from './order-summary/order-summary.module';
import {FooterModule} from './footer/footer.module';
import {MyorderModule} from './myorder/myorder.module';
import { StaffcontactComponent } from './detailview/staffcontact/staffcontact.component';
import { DeliveryAddressComponent } from './order-summary/delivery-address/delivery-address.component'
import { OrderStatusModule } from './order-status/order-status.module';
import { SignupModule } from './signup/signup.module';
import { OtpModule } from './otp/otp.module';
import { ForgotPasswordModule } from './forgot-password/forgot-password.module';
import { MyorderlistModule } from './myorderlist/myorderlist.module';
import { MyorderdetailModule } from './myorderdetail/myorderdetail.module';
import { UserMessageComponent } from './order-summary/user-message/user-message.component';


@NgModule({
  declarations: [
    AppComponent,
    DetailviewComponent,
    PaymentFailureComponent,
    PaymentSuccessComponent,
    ProfilePageComponent,
    StaffcontactComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HomePageModule,
    LoginModule,
    HeaderModule,
    FlexLayoutModule,
    HttpClientModule,
    NgbModule.forRoot(),
    PdfViewerModule,
    ListviewModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    FormsModule,
    CartModule,
    TreasuryModule,
    OrderDetailModule,
    SlideshowModule,
    OrderSummaryModule,
    FooterModule,
    MyorderModule,
    OrderStatusModule,
    SignupModule,
    OtpModule,
    ForgotPasswordModule,
    MyorderlistModule,
    MyorderdetailModule,
    MatSelectModule,
    NgbAlertModule
  ],
  providers: [],
  entryComponents: [ListpopupComponent, StaffcontactComponent, DeliveryAddressComponent, UserMessageComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }

<div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="center center" id="otp_page">
  <div fxFlex="33"></div>
  <div fxFlex="33" fxLayout="row wrap">
  <h3 fxFlex="100">OTP</h3>
  <ngb-alert *ngIf="error" type='danger' dismissible='true' (close)="error = null">{{error}}</ngb-alert>

  <p *ngIf="!error" fxFlex="100"> An otp has been send to {{oldNum}}. please enter the otp</p>
  <div fxFlex="100" fxFlexOffset="1">
      <mat-form-field *ngIf="editNum" fxFlex="20" appearance="outline">
          <input matInput readonly [value]='countryCode' placeholder="Code" >
        </mat-form-field>
      <mat-form-field *ngIf="editNum" fxFlex="80" style="margin-left:5px;" appearance="outline">
      <input matInput [(ngModel)]="phoneNumber" placeholder="Verify number">
    </mat-form-field>
    <mat-form-field fxFlex="100" appearance="outline">
      <input matInput [(ngModel)]="verificationCode" placeholder="">
    </mat-form-field>
  
  <!-- <div fxFlex="15" fxFlexOffset="1">
    <mat-form-field fxFlex="100" appearance="outline">
      <input matInput placeholder="">
    </mat-form-field> -->
  <!-- </div>
  <div fxFlex="15" fxFlexOffset="1">
    <mat-form-field fxFlex="100" appearance="outline">
      <input matInput placeholder="">
    </mat-form-field>
  </div>
  <div fxFlex="15" fxFlexOffset="1">
    <mat-form-field fxFlex="100" appearance="outline">
      <input matInput placeholder="">
    </mat-form-field>
  </div>
  <div fxFlex="15" fxFlexOffset="1">
    <mat-form-field fxFlex="100" appearance="outline">
      <input matInput placeholder="">
    </mat-form-field>
  </div>
  <div fxFlex="15" fxFlexOffset="1">
    <mat-form-field fxFlex="100" appearance="outline">
      <input matInput placeholder="">
    </mat-form-field> -->
  </div>
  <div fxFlex="100" >
    <a (click)="editNum = true">Wrong number? edit now</a>
  </div>
  <button class="otp_btn" mat-button (click)="verifyLoginCode()">Proceed</button>
  <button class="otp_btn" mat-button (click)="sendLoginCode()">Resend</button>

</div>
<div fxFlex="33"></div>
 
</div>
<div id="recaptcha-container"></div>
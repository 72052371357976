  <div fxFlex="100" fxLayout="row wrap" id="myorderpage">
      <h1>My Orders</h1>
    <div fxFlex="100" *ngFor="let order of orders" class="order_list_separation">
      <h5>Order Id:  
        <a *ngIf="order?.type !== 'made_to_order'" [routerLink]="'/myorderlistdetail/'+order.id">{{order.id}}</a>
        <a *ngIf="order?.type == 'made_to_order' && order?.status == 'ready_to_dispatch'" [routerLink]="'/orderstatus/'+order.id">{{order.id}}</a>
        <a *ngIf="order?.type == 'made_to_order' && order?.status !== 'ready_to_dispatch'" [routerLink]="'/myorderlistdetail/'+order.id">{{order.id}}</a>
      </h5>
      <p>{{order?.created_at | date: 'EEEE, MMMM d, y'}}</p>
      <p *ngIf="order?.order_status">{{order?.order_status}}</p>
      <p *ngIf="!order?.order_status">{{order?.status}}</p>
      <p *ngIf="order?.type == 'made_to_order'">Made to order</p>
      <p *ngIf="order?.type == 'Normal'">Normal</p>
      <p *ngIf="!order?.type">Normal</p>
      <p *ngIf="order?.type == 'COD'">Cash on delivery</p>
    </div>
    <div *ngIf="orders?.length === 0" fxFlex="100" >
      <p>
        It's empty here. Please make an order now. 
      </p>
    </div>
  </div>

<div fxLayout="row wrap" id="order_summary" fxFlex="100">
  <div fxFlex.gt-xs="70" fxFlex.xs="100" fxLayout="row wrap">

    <!-- stepper -->

    <mat-horizontal-stepper fxFlex="100" [linear]="isLinear" #stepper>
      <mat-step [stepControl]="firstFormGroup">
        <form>
          <ng-template matStepLabel>Summery</ng-template>
          <div fxLayout="row wrap" fxFlex="100">
            <mat-tab-group fxLayout="row wrap" fxFlex="100">
              <mat-tab label="Your Cart">
                <div class="summary_sec" fxFlex="100" *ngFor="let order of orderStatus" fxLayout="row wrap">
                  <div class="summary_image_sec" fxFlex.gt-xs="20" fxFlex.xs="100">
                    <span class="image_view">
                      <img [src]="order.image" draggable="false" />
                    </span>
                  </div>
                  <div fxFlex.gt-xs="30" fxFlex.x="100" class="item_details">
                    <h2>{{order.code}}</h2>
                    <h3>{{order.item}}</h3>
                    <p class="delete_sec">
                      <a href="">Save for later</a>
                      <a href="">delete</a>
                    </p>
                  </div>
                  <div class="size_deatils" fxFlex.gt-xs="30" fxFlex.xs="100" fxLayout="row wrap">

                    <mat-form-field fxFlex="50" appearance="outline">

                      <mat-select placeholder="size">
                        <mat-option [value]="order.size">
                          {{order.size}}
                        </mat-option>
                        <mat-option [value]="order.size">
                          50
                        </mat-option>
                        <mat-option [value]="order.size">
                          60
                        </mat-option>
                      </mat-select>
                    </mat-form-field>


                    <mat-form-field fxFlex="50" appearance="outline">

                      <mat-select placeholder="quantity">
                        <mat-option [value]="order.quantity">
                          {{order.quantity}}
                        </mat-option>
                        <mat-option [value]="order.quantity">
                          2
                        </mat-option>
                        <mat-option [value]="order.quantity">
                          3
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="price_details" fxFlex.gt-xs="20" fxFlex.xs="100">
                    <p>Price : {{order.price}}</p>
                  </div>
                </div>
              </mat-tab>
              <mat-tab label="Saved Items">
                <div class="summary_sec" fxFlex="100" *ngFor="let order of orderStatus" fxLayout="row wrap">
                  <div class="summary_image_sec" fxFlex.gt-xs="20" fxFlex.xs="100">
                    <span class="image_view">
                      <img [src]="order.image" draggable="false" />
                    </span>
                  </div>
                  <div fxFlex.gt-xs="30" fxFlex.xs="100" class="item_details">
                    <h2>{{order.code}}</h2>
                    <h3>{{order.item}}</h3>
                    <p class="delete_sec">
                      <a href="">Save for later</a>
                      <a href="">delete</a>
                    </p>
                  </div>
                  <div class="size_deatils" fxFlex.gt-xs="30" fxFlex.xs="100" fxLayout="row wrap">

                    <mat-form-field fxFlex="50" appearance="outline">

                      <mat-select placeholder="size">
                        <mat-option [value]="order.size">
                          {{order.size}}
                        </mat-option>
                        <mat-option [value]="order.size">
                          50
                        </mat-option>
                        <mat-option [value]="order.size">
                          60
                        </mat-option>
                      </mat-select>
                    </mat-form-field>


                    <mat-form-field fxFlex="50" appearance="outline">

                      <mat-select placeholder="quantity">
                        <mat-option [value]="order.quantity">
                          {{order.quantity}}
                        </mat-option>
                        <mat-option [value]="order.quantity">
                          2
                        </mat-option>
                        <mat-option [value]="order.quantity">
                          3
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="price_details" fxFlex.gt-xs="20" fxFlex.xs="100">
                    <p>Price : {{order.price}}</p>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>

          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="secondFormGroup">
        <form>
          <ng-template matStepLabel>Shipping address</ng-template>

          <div fxLayout="row wrap" fxFlex="100" class="shipping_sec">
            <div fxFlex.gt-xs="50" fxFlex.xs="100" fxLayout="row wrap">
              <mat-form-field fxFlex="100" appearance="outline">
                <input matInput placeholder="Mobile number">
                <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
              </mat-form-field>
              <mat-form-field fxFlex="100" appearance="outline">
                <input matInput placeholder="Enter your password" [type]="hide ? 'password' : 'text'">
                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hide">
                  <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
              </mat-form-field>
              <p fxFlex="100">
                <a>
                  Forgot Password?
                </a>
              </p>

              <button class="login_btn" mat-button>Login and continue</button>
            </div>


            <div fxFlex="100">
              <div fxFlex.gt-xs="50" fxFlex.xs="100" fxLayout="row wrap">
                <mat-form-field fxFlex="100" appearance="outline">
                  <input matInput placeholder="Address">
                  <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
                </mat-form-field>

                <mat-form-field fxFlex="100" appearance="outline">

                  <mat-select placeholder="city">
                    <mat-option [value]="">
                      Trivandrum
                    </mat-option>
                    <mat-option [value]="">
                      kollam
                    </mat-option>
                    <mat-option [value]="">
                      Ernakulam
                    </mat-option>
                  </mat-select>
                </mat-form-field>


                <mat-form-field fxFlex="100" appearance="outline">
                  <input matInput placeholder="pincode">
                  <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
                </mat-form-field>
                <button class="login_btn" mat-button>Payment</button>
              </div>
            </div>



          </div>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Payment</ng-template>
        You are now done.
        <div>

        </div>
      </mat-step>
    </mat-horizontal-stepper>

    <!-- stepper -->



  </div>
  <div fxFlex.gt-xs="30" fxFlex.xs="100" fxLayout="row wrap">
    <div fxFlex="100" fxLayout="row wrap"  class="ordersummary">
      <h2 fxFlex="100">Order Summary <span>4 Items</span></h2>
      <h4 fxFlex="100">Order value <span>$6000</span></h4>
      <h4 fxFlex="100">Shipping <span>Free</span></h4>
      <h5 fxFlex="100">Grand total <span>$6000</span></h5>
      <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
        <button class="order_btn" mat-button>Place Order</button>
      </div>

    </div>


  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreasuryRoutingModule} from './treasury-routing.module'

import { TreasuryComponent } from './treasury.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TreasurypopComponent } from './treasurypop/treasurypop.component';
import {MatDialogModule} from '@angular/material/dialog';


@NgModule({
  declarations: [TreasuryComponent, TreasurypopComponent],
  imports: [
    CommonModule,
    TreasuryRoutingModule,
    FlexLayoutModule,
    MatDialogModule
  ],
  exports : [TreasuryComponent],
  entryComponents : [TreasurypopComponent]

})
export class TreasuryModule { }

<div fxLayout="row wrap" fxFlex="100" id="orderstatus">
  <div fxFlex="100" fxLayout="row wrap" class="status_outer">
    <h1 fxFlex="100">My orders</h1>
    <div  fxFlex.gt-sm="40" fxFlex.xs="100" fxLayout="row wrap" class="status_sec">
      <div fxFlex="100" *ngFor="let item of collection | paginate: { itemsPerPage: 5, currentPage: p }" class="left_sec" fxLayout="row wrap">
        <div fxFlex.gt-xs="20" fxFlex.xs="100">
            <div class="image_sec">
              <img [src]="productImgUrl + item.product_image" draggable="false"/>
            </div>
          </div> 
          <div fxFlex.gt-sm="75" fxFlex.xs="100" fxFlexOffset.gt-xs="5">
        <h5 *ngIf="item.name"><b>{{item?.name}}</b></h5>
        <h5 *ngIf="!item.name"><b>{{item?.product_id}}</b></h5>
        <p>{{item?.description}}</p>
          </div>
      </div>
    </div>
    <div  *ngIf="order" fxFlex.gt-sm="55" fxFlex.sm="100" fxFlex.xs="100" fxFlexOffset.gt-sm="5" class="right_sec" fxLayout="row wrap">
        <div fxFlex="100" class="status">
          <!-- <h5 *ngIf="order?.order_status">{{order?.order_status}}</h5> -->
          <h5 *ngIf="order?.status">{{order?.status}}</h5>
        </div>
              <div *ngIf="order?.orderInfo" fxFlex="50">
            <h5>Advance amount: {{order?.orderInfo?.currency}} {{order?.orderInfo?.amount}}</h5>
            <h5>Balance amount: {{order?.orderInfo?.currency}} {{order?.balance_amount}}</h5>

              </div>
        <div *ngIf="order?.orderInfo" fxFlex="50">
          <p>Address: <span>{{address}}</span> </p>

        </div>
        <div *ngIf="order?.type == 'made_to_order' && order?.order_status == 'ready_to_dispatch'" fxLayout="row wrap" fxFlex="100"  id="country_pop">
          <p>Your product is now ready to dispatch. Please make the pending payment to complete your order</p>
        <div class="btn_sec">
          <button mat-button  [disabled]="!encRequest" (click)="pay()">Complete payment</button>
        </div>
        </div>
      </div>

      <form #form ngNoForm
      id="nonseamless" 
      method="post" 
      name="redirect" 
      action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction">
        <input type="hidden" id="encRequest" name="encRequest" value="{{encRequest}}">
        <input type="hidden" name="access_code" id="access_code" value="{{accessCode}}">
    </form>
    <div fxFlex="100" fxLayout="row wrap" class="pagination_sec" fxLayoutAlign="center center">
      <pagination-controls (pageChange)="p = $event"></pagination-controls>
    </div>
     
  </div>
</div>

<div class="loader" #loader>
  <svg>
      <g>
        <path d="M 50,100 A 1,1 0 0 1 50,0"/>
      </g>
      <g>
        <path d="M 50,75 A 1,1 0 0 0 50,-25"/>
      </g>
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" style="stop-color:#5f1715;stop-opacity:1" />
          <stop offset="100%" style="stop-color:#f7f7e6;stop-opacity:1" />
        </linearGradient>
      </defs>
    </svg>
</div>
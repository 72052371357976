
<div class="popup">

  <div fxLayout="row wrap" fxFlex="100" id="country_pop">
    <h3 fxFlex="100" class="head">Select country to ship</h3>
    <mat-form-field fxFlex="100" appearance="outline">
      <mat-select placeholder="Select your country" (selectionChange)="getShipping($event)" >
        <mat-option *ngFor="let country of countries; let i = index" [value]="country.country_code">{{country.country_name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="details_sec" *ngIf="charges && !charges.error">
      <p> Shipping charge: {{charges.shipping_charge}} {{currency}}</p> <br/>
      <p> Total: {{charges.total}} {{currency}} </p>
    </div>
    <div class="details_sec" *ngIf="charges?.error">
      <p>{{charges.error}}</p>  
    </div>
    
    <div *ngIf="charges && !charges.error" class="btn_sec">
      <button mat-button (click)="continue()" >Continue</button>
    </div>
  </div>
  
  <div class="loader" #loader>
    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
  </div>
  
</div>

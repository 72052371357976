import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit {

  constructor() { }

  orderlist: any = [];
  ngOnInit() {

    this.orderlist = [
      {
        image : 'assets/images/product1.png',
        text : 'product1',
        value : '$500',
        delivered: 'delivered'
    },
      {
        image : 'assets/images/product1.png',
        text : 'product1',
        value : '$500',
        delivered: 'delivered'
    },
      {
        image : 'assets/images/product1.png',
        text : 'product1',
        value : '$500',
        delivered: 'delivered'
    },
      {
        image : 'assets/images/product1.png',
        text : 'product1',
        value : '$500',
        delivered: 'delivered'
    }
  ];
  }

}

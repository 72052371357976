<div fxLayout="row wrap" fxFlex="100" id="detail_view">
  <div fxLayout="row wrap" fxFlex="100" class="detail_sec_outer">
    <div fxFlexOrder.gt-xs="2" fxFlexOrder.xs="1" fxLayout="row wrap" fxFlex.gt-xs="50" fxFlex.xs="100" class="image_sec">
      <div class="image_view">
        <img *ngIf="imageArray?.length > 0" [src]="isHovered?hoveredImage:defaultImage?.image"  />
      </div>


      <div fxFlex="25" class="image_preview_margin" fxLayout="row wrap" fxLayoutAlign="center center" *ngFor="let image of imageArray">
        <span class="image_preview" [ngClass]="{'border_active':defaultImage?.image == image.image}">
          <img [src]="image?.image" (mouseover)="mousein(image)" (mouseout)="mouseout()" 
          (touchstart)="mousein(image)" (touchend)="mouseout()" (click)="changeDeafult(image)" draggable="false"/>
        </span>
      </div>

    </div>
    <div fxFlexOrder.gt-xs="1" fxFlexOrder.xs="2" fxLayout="row wrap" fxFlex.gt-xs="50" fxFlex.xs="100" class="detail_sec">
      <div fxLayout="row wrap" fxFlex="100" class="inner_detail_view">
        <h3 fxFlex="100" *ngIf="!productTitle" class="code">{{productId}}</h3>
        <h3 fxFlex="100" *ngIf="productTitle" class="title">{{productTitle}}</h3>
        <p fxFlex="100" class="discription">{{productDesc}}</p>
        <div fxFlex="100" fxLayoutAlign="start center">
          <span class="price">{{productPrice}}</span>
        </div>

        <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start center" *ngIf="rating?.length > 0" class="rating">
          <p>Reviews</p>
          <span *ngFor="let star of rating">
            <i class="material-icons">
              star
            </i>
          </span>
        </div>
        <div class="btn_sec" fxFlex="100" fxLayout="row wrap">
          <div fxFlex="50" fxLayout="row wrap" fxLayoutAlign="start center">
            <button *ngIf="!soldOut" mat-button (click)="addToCart()">Add to cart</button>
            <button *ngIf="soldOut" [disabled]="soldOut" mat-button >Sold Out</button>

          </div>
          <div fxFlex="50" fxLayout="row wrap" fxLayoutAlign="end center">
            <p class="maid_order">{{type}}</p>
          </div> 
        </div>

      </div>


    </div>
  </div>

  <form #form ngNoForm id="nonseamless" method="post" name="redirect"
    action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction">
    <input type="hidden" id="encRequest" name="encRequest" value="{{encRequest}}">
    <input type="hidden" name="access_code" id="access_code" value="{{accessCode}}">
  </form>




<!-- footer -->
<!-- footer -->
</div>

<div class="loader" #loader>
    <svg>
        <g>
          <path d="M 50,100 A 1,1 0 0 1 50,0"/>
        </g>
        <g>
          <path d="M 50,75 A 1,1 0 0 0 50,-25"/>
        </g>
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" style="stop-color:#5f1715;stop-opacity:1" />
            <stop offset="100%" style="stop-color:#f7f7e6;stop-opacity:1" />
          </linearGradient>
        </defs>
      </svg>
</div>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service'
@Component({
  selector: 'app-myorderlist',
  templateUrl: './myorderlist.component.html',
  styleUrls: ['./myorderlist.component.scss']
})
export class MyorderlistComponent implements OnInit {
  userId;
  orders;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.userId = this.route.snapshot.params.userId
    this.api.getUserOrders(this.userId)
      .subscribe((res:any)=>{
        console.log("ordersss", res)
        this.orders = res.result.filter(order => order.status || order.order_status).sort((a:any, b:any)=>{
          let aDate = new Date(a.created_at).getTime()
          let bDate = new Date(b.created_at).getTime()
          if(aDate>bDate)
          return -1
          else if(bDate>aDate)
          return 1
        })
      })
  }

}

<div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="center center" id="profile_page">

  <div fxLayout="row wrap" fxFlex.gt-xs="70" fxFlex.xs="100" class="profile_left_sec">
    <h2 fxFlex="100">My Profile</h2>
    <div fxLayout="row wrap" fxFlex="100" class="profile_cover_sec">
      <span class="overlay"></span>
      <div class="profile_img_pic">
        <!-- <span class="profile_edit">
              <mat-icon>camera_alt</mat-icon>
          </span> -->
        <img [src]="profile.profile_image? s3Url + profile.profile_image:'assets/images/profile.png'" />
      </div>
    </div>

    <div fxFlex="100" class="name_sec" fxLayout="row wrap">
      <div fxFlex="100" fxLayout="row wrap" class="profile_right_sec">
        <div fxHide.xs fxFlex="30" fxLayout="row wrap" fxLayoutAlign="start center">
          <h4 fxFlex="100">Name</h4>
        </div>
        <div fxFlex.gt-xs="70" fxFlex.xs="100" fxLayout="row wrap">
          <mat-form-field fxFlex="100" appearance="outline">
            <input matInput placeholder="Name" [(ngModel)]="profile.username">
          </mat-form-field>
        </div>
        <div fxHide.xs fxFlex="30" fxLayout="row wrap" fxLayoutAlign="start center">
          <h4 fxFlex="100">Mobile</h4>
        </div>
        <div fxFlex.gt-xs="70" fxFlex.xs="100" fxLayout="row wrap">
          <mat-form-field fxFlex="100" appearance="outline">
            <input matInput placeholder="Mobile" readonly [value]="phonenumber">
          </mat-form-field>
        </div>


        <div fxHide.xs fxFlex="30" fxLayout="row wrap" fxLayoutAlign="start center">
          <h4 fxFlex="100">Email</h4>
        </div>
        <div fxFlex.gt-xs="70" fxFlex.xs="100" fxLayout="row wrap">
          <mat-form-field fxFlex="100" appearance="outline">
            <input matInput placeholder="Email" readonly [(ngModel)]="profile.email">
          </mat-form-field>
        </div>
        <div fxHide.xs fxFlex="30" fxLayout="row wrap" fxLayoutAlign="start center">
          <h4 fxFlex="100">Current password</h4>
        </div>
        <div fxFlex.gt-xs="70" fxFlex.xs="100" fxLayout="row wrap">
          <mat-form-field fxFlex="100" appearance="outline">
            <input matInput type="password" placeholder="Password"  [(ngModel)]="password" (click)="errorMessage = null">
          </mat-form-field>
        </div>
   

        <div fxHide.xs fxFlex="30" fxLayout="row wrap" fxLayoutAlign="start center">
          <h4 fxFlex="100">New password</h4>
        </div>
        <div fxFlex.gt-xs="70" fxFlex.xs="100" fxLayout="row wrap">
          <mat-form-field fxFlex="100" appearance="outline">
            <input matInput type="password" placeholder="New password"  [(ngModel)]="newPassword" (click)="errorMessage = null">
          </mat-form-field>
        </div>

        <div fxHide.xs fxFlex="30" fxLayout="row wrap" fxLayoutAlign="start center">
          <h4 fxFlex="100">Confirm password</h4>
        </div>
        <div fxFlex.gt-xs="70" fxFlex.xs="100" fxLayout="row wrap">
          <mat-form-field fxFlex="100" appearance="outline">
            <input matInput type="password" placeholder="Confirm password"  [(ngModel)]="confirmPassword" (click)="errorMessage = null">
          </mat-form-field>
        </div>
        <p *ngIf="errorMessage" fxFlex="100" >
            <ngb-alert [dismissible]="false" type="danger">
             {{errorMessage}}
            </ngb-alert>
          </p>
          <p *ngIf="successMessage" fxFlex="100" >
              <ngb-alert [dismissible]="false" type="success">
               {{successMessage}}
              </ngb-alert>
            </p>
        <div fxFlex="100" fxLayoutAlign="center center">
          <button class="submit_btn" mat-button (click)="resetPwd()">Reset password</button>
        </div>
      </div>
    </div>
  </div>


</div>
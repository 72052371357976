import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OtpComponent } from './otp.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule, MatButtonModule, MatSelectModule, MatInputModule } from '@angular/material';
import { AngularFireModule } from '@angular/fire'
import { FormsModule } from '@angular/forms'
import { AngularFireAuthModule } from '@angular/fire/auth'
import {environment} from '../../environments/environment'
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap'
@NgModule({
  declarations: [OtpComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    FormsModule,
    NgbAlertModule
  ]
})
export class OtpModule { }

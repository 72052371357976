import { Component, OnInit, Input, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material'
import { ApiService } from '../../services/api.service';
@Component({
  selector: 'app-country-popup',
  templateUrl: './country-popup.component.html',
  styleUrls: ['./country-popup.component.scss']
})
export class CountryPopupComponent implements OnInit {
  @ViewChild('loader') loader
  currency
  countries;
  selectedCountry = 'Select Country';
  charges;
  cart;
  continue_btn = true
  constructor(
    private api: ApiService,
    public dialogRef: MatDialogRef<CountryPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.cart = data.cart
    this.currency = data.currency
   }

  ngOnInit() {
    console.log("carttttt",this.cart)
    this.api.get_shipping_countries()
    .subscribe((cur:any)=>{
      this.countries = this.filterCountries(cur)
      console.log(this.countries)
      this.loader.nativeElement.style.display = 'none'
      })
  }

  filterCountries(cur){
    let oldCur = []
    let countries = []
    cur.forEach((i) => {
        if(!oldCur.includes(i.country_name)){
          oldCur.push(i.country_name)
          countries.push(i)
        }
    });
    return countries
  }

  async getShipping(event){
    this.loader.nativeElement.style.display = 'flex'
    this.selectedCountry = event.value
    this.charges = await this.getShippingCharge(event.value)
    if(this.charges)
    this.loader.nativeElement.style.display = 'none'
    console.log(this.charges)
  }

  getShippingCharge(country){
    return new Promise((resolve, reject)=>{
      console.log("currencyyyy", this.currency)
      console.log(this.cart)
      let req = {
        country_code: country,
        currency: this.currency,
        products: this.cart.map((item: any) => {
          console.log("Productttt",item.Productdata)
          return {
            weight: Number(item.Productdata.weight),
            quantity: item.quantity,
            total: (typeof item.total == 'string')?Number(item.total.split(' ')[0]): item.total
          }
        })
      }
      console.log("shipinngg infoooosss", req)
      this.api.get_shipping_charge(req)
      .subscribe((res:any)=>{
        if(res){
          let result = JSON.parse(res.result)
          resolve(result.response)
        }else{
          reject("No response received")
        }
        
      })
    })
  }

  continue(){
    this.dialogRef.close({
      ...this.charges,
      shipping_country: this.selectedCountry
    })
  }
}

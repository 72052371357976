import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyorderRoutingModule } from './myorder-routing.module'
import { MyorderComponent } from './myorder.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';

@NgModule({
  declarations: [MyorderComponent],
  imports: [
    CommonModule,
    MyorderRoutingModule,
    FlexLayoutModule,
    MatStepperModule,
    MatTabsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule
  ],
  exports: [
    MyorderComponent
  ]
})
export class MyorderModule { }

<section class="footer w-100">
    <div class="container-fluid">
      <div class="row justify-content-md-center">
        <div class="col-sm-4">
          <ul class="about_us_sec">
            <li><a (click)="onModal(about)">
                About us
              </a>
            </li>
            <li><a (click)="onModal(contact)">
                Contact us
              </a>
            </li>
          </ul>
        </div>
        <div class="col-sm-4">
          <ul class="about_us_sec">
            <li>
              <a (click)="onModal(terms)">
                Terms and conditions
              </a>
            </li>
            <li>
              <a (click)="onModal(privacy)">
                Privacy policy
              </a>
            </li>
           
              <li>
                  <a (click)="onModal(returnPolicy)">
                Return Policy
              </a>
              </li>
          </ul>
        </div>
        <div class="col-sm-4">
          <ul class="about_us_sec">
            <li>
                <a target = "_blank" href="https://www.facebook.com/Tazzels3/">
                  Facebook 
                </a>
            </li>
            <li>
                <a target = "_blank" href="https://www.Instagram.com/Tazzels3/">
                  Instagram 
                </a>
            </li>

          </ul>
        </div>
      </div>
    </div>

  </section>

  
  <ng-template #about>
    <div class="modal-header">
      <h4 class="modal-title pull-left">About Us</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>
        <pdf-viewer [src]="aboutUs" [render-text]="true" [show-all]="true" [fit-to-page]="true" style="display: block;"></pdf-viewer>
      </p>
    </div>
  </ng-template>

  <ng-template #terms>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Terms and conditions</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>
        <pdf-viewer [src]="termsAndCond" [render-text]="true" [show-all]="true" [fit-to-page]="true" style="display: block;">PDF viewer</pdf-viewer>
      </p>
    </div>
  </ng-template>

  <ng-template #privacy>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Privacy policy</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>
        <pdf-viewer [src]="privacyAndPolicy" [render-text]="true" [show-all]="true" [fit-to-page]="true" style="display: block;"></pdf-viewer>
      </p>
    </div>
  </ng-template>
  <ng-template #returnPolicy>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Return Policy</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>
        <pdf-viewer [src]="returnPolicyDoc" [render-text]="true" [show-all]="true" [fit-to-page]="true" style="display: block;"></pdf-viewer>
      </p>
    </div>
  </ng-template>

  <ng-template #contact>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Our Address</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>
        <b>India</b><br>
        Tazzels3<br>
        11/769/15 Meledath Stephan Memorial Building, <br>
        Mother Junction, Ollur, Thrissur<br>
        680306 <br>
        Ph No. +919886046697, +917560832927<br>
        Email : tazzles3@gmail.com
        <br/>
        <br/>
        <b>UAE</b>
        <br>
        Tazzels3.com LLC <br>
        Sharjah Media City <br>
        Sharjah<br>
        Ph No. +971557167369, +971503410797

      </p>
    </div>
  </ng-template>
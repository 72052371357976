import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyorderlistComponent } from './myorderlist.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {NgxPaginationModule} from 'ngx-pagination';
import { MyorderlistRoutingModule } from './myorderlist-routing.module'

@NgModule({
  declarations: [MyorderlistComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatStepperModule,
    MatTabsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule,
    NgxPaginationModule,
    MyorderlistRoutingModule
  ]
})
export class MyorderlistModule { }

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  ccav_accessCode: 'AVMN85GE54BW64NMWB',
  apiUrl: 'https://backend-api.tazzels3.com/api/',
  s3_url: 'https://s3.amazonaws.com/tazzels3imagebutcket/',
  return: 'https://s3.amazonaws.com/tazzels3imagebutcket/return_policy/Refund-return-policy.pdf',
  firebaseConfig: {
    apiKey: "AIzaSyDZRHkb3tvX3Ao5TN5H1tGvp1slw8IxMhQ",
    authDomain: "tazzels3-6b29c.firebaseapp.com",
    databaseURL: "https://tazzels3-6b29c.firebaseio.com",
    projectId: "tazzels3-6b29c",
    storageBucket: "tazzels3-6b29c.appspot.com",
    messagingSenderId: "59133907471",
    appId: "1:59133907471:web:95618ea84a46d751"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomePageComponent } from './home-page.component';
import { SliderModule } from 'angular-image-slider';
import {MatButtonModule} from '@angular/material/button';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ModalModule } from 'ngx-bootstrap';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatTooltipModule} from '@angular/material/tooltip';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MatSelectModule} from '@angular/material/select';
import {MatDividerModule} from '@angular/material/divider';
import {MatDialogModule} from '@angular/material/dialog';
import { HomePopupComponent } from './home-popup/home-popup.component';

import { HomePageRoutingModule } from './homepage-routing.module'


@NgModule({
  declarations: [HomePageComponent, HomePopupComponent],
  imports: [
    CommonModule,
    HomePageRoutingModule,
    SliderModule,
    MatButtonModule,
    ModalModule.forRoot(),
    PdfViewerModule,
    FlexLayoutModule,
    MatTooltipModule,
    NgbModule,
    MatSelectModule,
    MatDividerModule,
    MatDialogModule
  ],
  entryComponents : [HomePopupComponent],
})
export class HomePageModule { }

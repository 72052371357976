<div fxLayout="row wrap" fxFlex="100" id="signup">
  
  <div fxHide.xs fxHide.sm fxFlex.gt-xs="33">
  </div>  
  <div [formGroup]="signup" fxFlex.gt-xs="33" fxFlex.sm="100" fxFlex.xs="100" fxLayout="row wrap" fxLayoutAlign="center center">
      <h3>Sign Up</h3>
         <p  fxFlex="100" *ngIf="error" class="alert" >
          <ngb-alert [dismissible]="false" type="danger" (close)="error = null">
             {{error}}
          </ngb-alert>
        </p>
      <mat-form-field fxFlex="100" appearance="outline">
          <input matInput formControlName="username" placeholder="Full Name">
        </mat-form-field>
        <mat-form-field fxFlex="100" appearance="outline">
            <mat-select formControlName="countryname" placeholder="Select your country" (selectionChange)="countrySelected($event.value)">
              <mat-option *ngFor="let country of countries" [value]="country.name">{{country.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="20" appearance="outline">
            <input matInput readonly [value]='dialCode' placeholder="Code" >
          </mat-form-field>
      <mat-form-field fxFlex="78" style="margin-left:5px;" appearance="outline">
          <input matInput formControlName="phoneno" placeholder="Phone Number">
        </mat-form-field>
      <mat-form-field fxFlex="100" appearance="outline">
          <input matInput formControlName="email" placeholder="Email Id">
        </mat-form-field>
      <mat-form-field fxFlex="100" appearance="outline">
          <input matInput formControlName="password" type="password" placeholder="Password">
        </mat-form-field>
        <p fxFlex="100"> Password must be ateast 8 characters long, Must include capital letter, small letter, special character and number</p>
        <mat-form-field  fxFlex="100" appearance="outline">
          <input matInput type="password" formControlName="confirmPwd"  placeholder="Confirm Password">
        </mat-form-field>
        <mat-checkbox fxFlex="100" formControlName="agree">I have read & agree to the <a>Terms and conditions</a></mat-checkbox>
      </div>
      <div fxFlex="100" class="signup_action">
        <button (click)="Signup()"  class="sign-up-btn" mat-button>SignUp</button>
        <p class="login-sec" fxFlex="100">Already user? <span (click)="onLogin()">Login</span></p>
      </div>

 
  <div fxFlex.gt-xs="33" fxHide.sm fxHide.xs>
  </div>  
</div>

<div class="loader" #loader>
    <svg>
        <g>
          <path d="M 50,100 A 1,1 0 0 1 50,0"/>
        </g>
        <g>
          <path d="M 50,75 A 1,1 0 0 0 50,-25"/>
        </g>
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" style="stop-color:#5f1715;stop-opacity:1" />
            <stop offset="100%" style="stop-color:#f7f7e6;stop-opacity:1" />
          </linearGradient>
        </defs>
      </svg>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CartRoutingModule } from './cart-routing.module'

import { CartComponent } from './cart.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import { CountryPopupComponent } from './country-popup/country-popup.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap'
@NgModule({
  declarations: [CartComponent, CountryPopupComponent],
  imports: [
    CommonModule,
    CartRoutingModule,
    FlexLayoutModule,
    MatButtonModule,
    RouterModule,
    MatDividerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    NgbAlertModule
    ],
  exports:[
    CartComponent
  ],
  entryComponents:[CountryPopupComponent]
})
export class CartModule { }

<div class="loader" #loader>
  <svg>
      <g>
        <path d="M 50,100 A 1,1 0 0 1 50,0"/>
      </g>
      <g>
        <path d="M 50,75 A 1,1 0 0 0 50,-25"/>
      </g>
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" style="stop-color:#5f1715;stop-opacity:1" />
          <stop offset="100%" style="stop-color:#f7f7e6;stop-opacity:1" />
        </linearGradient>
      </defs>
    </svg>
</div>
<div fxLayout="row wrap" fxFlex="100" id="listing_view">
  <div fxLayout="row wrap" fxFlex="100" class="content_outer">
    <div fxFlex.gt-xs="25" fxFlex.xs="100" class="filter_sec">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Filter
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-checkbox aria-label="colorFilter" [disabled]="color==undefined || color==''" (change)="onFilter($event)">Color</mat-checkbox>
        <mat-form-field appearance="outline">
          <input matInput [(ngModel)]="color" placeholder="Color">
        </mat-form-field>
        <mat-checkbox aria-label="available" (change)="onFilter($event)">Available</mat-checkbox>
        <mat-checkbox aria-label="madetoorder" (change)="onFilter($event)">Made to order</mat-checkbox>
        <mat-checkbox aria-label="colorAvailablity" (change)="onFilter($event)">Made according to color availability</mat-checkbox>
        <button class="ok_btn" mat-button (click)="filterResult()">Ok</button>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Sort
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-radio-group fxFlex="100" aria-label="Select an option">
          <mat-radio-button aria-label="sortByPriceLowToHigh" value='low_to_high' (change)="onFilter($event)">Price low to high</mat-radio-button> <br />
          <mat-radio-button aria-label="sortByPriceHighToLow" value='high_to_low' (change)="onFilter($event)">Price High to Low</mat-radio-button> <br />
          <mat-radio-button aria-label="sortByPopularity" value='popularity' (change)="onFilter($event)">popularity</mat-radio-button> <br />
        </mat-radio-group>
        <button class="ok_btn" mat-button (click)="this.filterResult()">Ok</button>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Category
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
          <mat-radio-group fxFlex="100" aria-label="category" (change)="catFilter($event)">
      <ul>
        <li fxLayout="row wrap" fxFlex="100" *ngFor="let item of categories">
            <mat-radio-button [value]="item.id">{{item.category_name}}</mat-radio-button><br/>
          <div fxFlex="100" class="subcategory" fxLayout="row wrap">
            <ul>
            <li class="radio-button" *ngFor="let sub of item.subcategory"> 
            <mat-radio-button [value]="item.id + '-' + sub.id" >{{sub.sub_category_name}}</mat-radio-button>
          </li>
        </ul>
            <!-- <mat-radio-group fxFlex="100" aria-label="Select an option">
              <mat-radio-button value="3">Exquisite</mat-radio-button>
            </mat-radio-group> 
            <mat-radio-group fxFlex="100" aria-label="Select an option">
              <mat-radio-button value="4">Blouse</mat-radio-button>
            </mat-radio-group> -->
          </div>

        </li>
      </ul>
        </mat-radio-group>
        <!-- <div fxLayout="row wrap" fxFlex="100">
        <mat-radio-group fxFlex="100" aria-label="Select an option">
          <mat-radio-button value="5">Salwar</mat-radio-button>
        </mat-radio-group>
      </div>

      <div fxFlex="100" class="subcategory" fxLayout="row wrap">
          <mat-radio-group fxFlex="100" aria-label="Select an option">
              <mat-radio-button value="6">Daily wear</mat-radio-button>
            </mat-radio-group> 
            <mat-radio-group fxFlex="100" aria-label="Select an option">
              <mat-radio-button value="7">Occassional wear</mat-radio-button>
            </mat-radio-group> 
      </div>
     
      <div fxLayout="row wrap" fxFlex="100">
        <mat-radio-group fxFlex="100" aria-label="Select an option">
          <mat-radio-button value="8">Kurthi</mat-radio-button>
        </mat-radio-group>
      </div> -->
      </mat-expansion-panel>
    </div>
    <div fxFlex.gt-xs="75" class="list_view_sec" fxLayout="row wrap">
      <div fxFlex="100" fxLayout="row wrap" class="outer_layout">
        <div *ngFor="let product of products | paginate: { itemsPerPage: 9, currentPage: p }; let i = index" fxFlex.gt-xs="33" class="product_sec" fxLayout="row wrap" (click)="onProduct(product.id)">
          <div fxFlex="100" class="layout">
            <span class="image_sec">
              <img [src]="getImgUrl(product.product_image)" />
            </span>
            <p *ngIf="product.name?.length > 0" class="name">{{product.name}}</p>
            <p *ngIf="product.name?.length == 0 || !product.name" class="name">{{product.product_id}}</p>
            <p class="price">{{product.price}}</p>
          </div>
        </div>
        <!-- <div fxFlex.gt-xs="33" class="product_sec" fxLayout="row wrap">
          <div fxFlex="100" class="layout">
              <span class="image_sec">
                  <img src="assets/images/product1.png"/>
                </span>
                <p class="name">Product1</p>
                <p class="price">$200</p>
                <p class="rating">4.4</p>
          </div>
        
        </div>
        <div fxFlex.gt-xs="33" class="product_sec" fxLayout="row wrap">
          <div fxFlex="100" class="layout">
              <span class="image_sec">
                  <img src="assets/images/product1.png"/>
                </span>
                <p class="name">Product1</p>
                <p class="price">$200</p>
                <p class="rating">4.4</p>
          </div>
        
        </div>
        <div fxFlex.gt-xs="33" class="product_sec" fxLayout="row wrap">
          <div fxFlex="100" class="layout">
              <span class="image_sec">
                  <img src="assets/images/product1.png"/>
                </span>
                <p class="name">Product1</p>
                <p class="price">$200</p>
                <p class="rating">4.4</p>
          </div>
        
        </div>
        <div fxFlex.gt-xs="33" class="product_sec" fxLayout="row wrap">
          <div fxFlex="100" class="layout">
              <span class="image_sec">
                  <img src="assets/images/product1.png"/>
                </span>
                <p class="name">Product1</p>
                <p class="price">$200</p>
                <p class="rating">4.4</p>
          </div>
        
        </div> -->
      </div>
    </div>
        <div fxFlex="100" fxLayout="row wrap" class="pagination_sec" fxLayoutAlign="center center">
          <pagination-controls (pageChange)="p = $event"></pagination-controls>
        </div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [FooterComponent],
  imports: [
    CommonModule,
    PdfViewerModule,
    NgbModule
  ],
  exports: [
    FooterComponent
  ]
})
export class FooterModule { }
